import { IconNames } from '../models/assets-constants';

export enum ActivityKeys {
  Steps = 'steps',
  MediumIntensityMinutes = 'mediumIntensityMinutes',
  HighIntensityMinutes = 'highIntensityMinutes',
  Count = 'count',
  BikeKm = 'bikeKm',
  Syncs = 'syncs',
  Average = 'average',
}

export const ACTIVITIES_CONSTANTS: Record<
  ActivityKeys,
  { icon: string; unit: string; info: string; conversionRate?: number }
> = Object.freeze({
  [ActivityKeys.Steps]: {
    icon: IconNames.StepsOrangeFilled,
    unit: '',
    info: 'ACTIVITIES_CONSTANTS.STEPS',
    conversionRate: 1,
  },
  [ActivityKeys.MediumIntensityMinutes]: {
    icon: IconNames.ExerciseOrangeFilled,
    unit: 'UNITS.MIN',
    info: 'ACTIVITIES_CONSTANTS.MEDIUM_INTENSITY_MINUTES',
    conversionRate: 106.66, // 8.000 / 75
  },
  [ActivityKeys.HighIntensityMinutes]: {
    icon: IconNames.ExerciseOrangeFilled,
    unit: 'UNITS.MIN',
    info: 'ACTIVITIES_CONSTANTS.HIGH_INTENSITY_MINUTES',
    conversionRate: 133.33, // 8.000 / 60
  },
  [ActivityKeys.Count]: {
    icon: IconNames.StandingManWithOpenArms,
    unit: '',
    info: 'ACTIVITIES_CONSTANTS.COUNT',
  },
  [ActivityKeys.BikeKm]: {
    icon: IconNames.BikeOrangeFilled,
    unit: 'UNITS.KM',
    info: 'ACTIVITIES_CONSTANTS.BIKE_KM',
    conversionRate: 266.66, // 8.000 / 30
  },
  [ActivityKeys.Syncs]: {
    icon: IconNames.SyncOutlined,
    unit: '',
    info: 'ACTIVITIES_CONSTANTS.SYNCS',
  },
  [ActivityKeys.Average]: {
    icon: IconNames.BalanceAverage,
    unit: '',
    info: 'ACTIVITIES_CONSTANTS.AVERAGE',
  },
});
