import { Injectable } from '@angular/core';
import {
  CompanyCitiesChallenge,
  ICompanyCitiesChallengeInitializer,
} from '../../models/company-cities-challenge/company-cities-challenge/company-cities-challenge';
import { FirebaseService } from '../firebase/firebase.service';
import { UserMessageService } from '../user-message/user-message.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CitiesChallengeService } from '../cities-challenge/cities-challenge.service';
import { CitiesChallenge } from '../../models/cities-challenge/cities-challenge/cities-challenge.model';
import { FirebaseConstants } from '../../models/firebase-constants.enum';
import {
  CompanyParticipant,
  ICompanyParticipant,
} from '../../models/company-participant/company-participant';
import { BehaviorSubject } from 'rxjs';
import { Helpers } from '../../helpers/helpers';
import {
  CitiesChallengeDayPerformance,
  ICitiesChallengeDayPerformance,
} from '../../models/cities-challenge/cities-day-challenge-performance/cities-challenge-day-performance.model';
import { ParticipantPerformance } from '../../models/participant-performance/participant-performance';

@Injectable({
  providedIn: 'root',
})
export class CompanyCitiesChallengeService {
  public companyCitiesChallenges: Array<CompanyCitiesChallenge> = [];

  public nextCompanyCitiesChallengeUnsubscribe: () => void;
  public $nextCompanyCitiesChallenge = new BehaviorSubject<CompanyCitiesChallenge | null>(
    null
  );

  public currentCompanyCitiesChallengeUnsubscribe: () => void;
  public $currentCompanyCitiesChallenge = new BehaviorSubject<CompanyCitiesChallenge | null>(
    null
  );

  public finishedCompanyCitiesChallengesUnsubscribe: () => void;
  public $finishedCompanyCitiesChallenges = new BehaviorSubject<
    Array<CompanyCitiesChallenge>
  >([]);
  public $lastCompanyCitiesChallengeFinished = new BehaviorSubject<CompanyCitiesChallenge | null>(
    null
  );

  constructor(
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService,
    private userMessageService: UserMessageService,
    private translateService: TranslateService,
    private citiesChallengeService: CitiesChallengeService
  ) {}

  public getStandardCompanyCitiesChallengeDataForm(): UntypedFormGroup {
    const citiesChallengeFormGroup = this.citiesChallengeService.getStandardCitiesChallengeDataForm();
    return this.formBuilder.group({
      citiesChallenge: citiesChallengeFormGroup,
      participants: [[]],
    });
  }

  public setCompanyCitiesChallengeDataFormValues(
    companyCitiesChallengeDataForm: UntypedFormGroup,
    companyCitiesChallenge: CompanyCitiesChallenge
  ): void {
    this.citiesChallengeService.setCitiesChallengeDataFormValues(
      companyCitiesChallengeDataForm.get('citiesChallenge') as UntypedFormGroup,
      companyCitiesChallenge
    );
  }

  public getCompanyCitiesChallenges(): Promise<Array<CompanyCitiesChallenge>> {
    return this.firebaseService
      .getCompanyCitiesChallenges()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.companyCitiesChallenges = querySnapshot.docs.map(
            (docSnapshot) =>
              new CompanyCitiesChallenge(
                docSnapshot.data() as ICompanyCitiesChallengeInitializer
              )
          );
          return [...this.companyCitiesChallenges];
        } else {
          this.companyCitiesChallenges = [];
          return [];
        }
      })
      .catch((error) => {
        console.log(`Get group run challenges error: ${error}`);
        this.userMessageService.snackBarMessage(
          this.translateService.instant('DEFAULT_ERROR')
        );
        this.companyCitiesChallenges = [];
        return [];
      });
  }
  public getCompanyCitiesChallengesById(
    companyId: string
  ): Promise<Array<CompanyCitiesChallenge>> {
    return this.firebaseService
      .getCompanyCitiesChallengesById(companyId)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.companyCitiesChallenges = querySnapshot.docs.map(
            (docSnapshot) =>
              new CompanyCitiesChallenge(
                docSnapshot.data() as ICompanyCitiesChallengeInitializer
              )
          );
        } else {
          this.companyCitiesChallenges = [];
        }
        return [...this.companyCitiesChallenges];
      })
      .catch((error) => {
        console.log(`Get group run challenges error: ${error}`);
        this.userMessageService.snackBarMessage(
          this.translateService.instant('DEFAULT_ERROR')
        );
        this.companyCitiesChallenges = [];
        return [];
      });
  }

  public createCompanyCitiesChallenge(
    challenge: CitiesChallenge
  ): Promise<void> {
    if (!challenge.id) {
      challenge.id = this.firebaseService.getAutomaticIdInRootCollection(
        FirebaseConstants.CompanyCitiesChallengesCollection
      );
    }

    const companyCitiesChallenge = new CompanyCitiesChallenge(
      challenge.toObject()
    );

    return this.firebaseService.setCompanyCitiesChallenge(
      companyCitiesChallenge
    );
  }

  public updateCompanyCitiesChallenge(
    changes: Partial<ICompanyCitiesChallengeInitializer>
  ): Promise<void> {
    return this.firebaseService.updateCompanyCitiesChallenge(changes);
  }

  public subscribeToCompanyCitiesChallengeParticipants(
    challengeId: string,
    setParticipantsFunction: (participants: Array<CompanyParticipant>) => void
  ): () => void {
    return this.firebaseService.subscribeToCompanyCitiesChallengeParticipants(
      challengeId,
      {
        next: (querySnapshot) => {
          setParticipantsFunction(
            querySnapshot.docs.map(
              (docSnapshot) =>
                new CompanyParticipant(
                  docSnapshot.data() as ICompanyParticipant
                )
            )
          );
        },
        error: (error) => {
          console.log(
            `Get company cities challenge participants error: ${error}`
          );
          this.userMessageService.snackBarMessage(
            this.translateService.instant('DEFAULT_ERROR')
          );
          setParticipantsFunction([]);
        },
      }
    );
  }

  public updateCompanyCitiesChallengeParticipant(
    challengeId: string,
    participantId: string,
    update: Partial<ICompanyParticipant>
  ): Promise<void> {
    return this.firebaseService
      .updateCompanyCitiesChallengeParticipant(
        challengeId,
        participantId,
        update
      )
      .catch((error) => {
        console.log(
          `update company cities challenge participant error: ${error}`
        );
        this.userMessageService.snackBarMessage(
          this.translateService.instant('DEFAULT_ERROR')
        );
      });
  }

  public removeCompanyParticipant(
    challengeId: string,
    companyId: string
  ): Promise<void> {
    return this.firebaseService.removeCompanyCitiesChallengeParticipant(
      challengeId,
      companyId
    );
  }

  public async subscribeToNextCompanyCitiesChallenge(
    companyId: string
  ): Promise<void> {
    if (this.nextCompanyCitiesChallengeUnsubscribe !== undefined) {
      this.nextCompanyCitiesChallengeUnsubscribe();
    }
    try {
      this.nextCompanyCitiesChallengeUnsubscribe = this.subscriptionToNextCompanyCitiesChallenge(
        companyId
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private subscriptionToNextCompanyCitiesChallenge(
    companyId: string
  ): () => void {
    return this.firebaseService.subscribeToNextCompanyCitiesChallenge(
      companyId,
      {
        next: (querySnapshot) => {
          if (!querySnapshot.empty) {
            this.$nextCompanyCitiesChallenge.next(
              new CompanyCitiesChallenge(
                querySnapshot.docs[0].data() as ICompanyCitiesChallengeInitializer
              )
            );
          } else {
            this.$nextCompanyCitiesChallenge.next(null);
          }
        },
        error: (error) => {
          console.log('subscribeToNextCompanyCitiesChallenge - error: ', error);
          this.$nextCompanyCitiesChallenge.next(null);
          throw new Error(
            error +
              ' > company-cities-challenge.service - subscriptionToNextCompanyCitiesChallenge'
          );
        },
      }
    );
  }

  public async subscribeToCurrentCompanyCitiesChallenge(
    companyCitiesChallengeId: string
  ): Promise<void> {
    if (this.currentCompanyCitiesChallengeUnsubscribe !== undefined) {
      this.currentCompanyCitiesChallengeUnsubscribe();
    }
    try {
      this.currentCompanyCitiesChallengeUnsubscribe = this.subscriptionToCurrentCompanyCitiesChallenge(
        companyCitiesChallengeId
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private subscriptionToCurrentCompanyCitiesChallenge(
    companyId: string
  ): () => void {
    return this.firebaseService.subscribeToCurrentCompanyCitiesChallenge(
      companyId,
      {
        next: (querySnapshot) => {
          if (!querySnapshot.empty) {
            this.$currentCompanyCitiesChallenge.next(
              new CompanyCitiesChallenge(
                querySnapshot.docs[0].data() as ICompanyCitiesChallengeInitializer
              )
            );
          } else {
            this.$currentCompanyCitiesChallenge.next(null);
          }
        },
        error: (error) => {
          console.log(
            'subscribeToCurrentCompanyCitiesChallenge - error: ',
            error
          );
          this.$currentCompanyCitiesChallenge.next(null);
          throw new Error(
            error +
              ' > company-cities-challenge.service - subscriptionToCurrentCompanyCitiesChallenge'
          );
        },
      }
    );
  }

  public async subscribeToFinishedCompanyCitiesChallenges(
    companyId: string
  ): Promise<void> {
    if (this.finishedCompanyCitiesChallengesUnsubscribe !== undefined) {
      this.finishedCompanyCitiesChallengesUnsubscribe();
    }
    try {
      this.finishedCompanyCitiesChallengesUnsubscribe = this.subscriptionToFinishedCompanyCitiesChallenges(
        companyId
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private subscriptionToFinishedCompanyCitiesChallenges(
    companyId: string
  ): () => void {
    return this.firebaseService.subscribeToFinishedCompanyCitiesChallenges(
      companyId,
      {
        next: (querySnapshot) => {
          if (!querySnapshot.empty) {
            this.$finishedCompanyCitiesChallenges.next(
              querySnapshot.docs.map(
                (docSnapshot) =>
                  new CompanyCitiesChallenge(
                    docSnapshot.data() as ICompanyCitiesChallengeInitializer
                  )
              )
            );
          } else {
            this.$finishedCompanyCitiesChallenges.next([]);
          }
          this.$lastCompanyCitiesChallengeFinished.next(
            this.getCompanyCitiesChallengeFinishedLastWeek(
              this.$finishedCompanyCitiesChallenges.value
            )
          );
        },
        error: (error: firebase.default.firestore.FirestoreError) => {
          console.log(
            'subscriptionToFinishedCompanyCitiesChallenges - error: ',
            error
          );
          this.$finishedCompanyCitiesChallenges.next([]);
          this.$lastCompanyCitiesChallengeFinished.next(null);
          throw new Error(
            error +
              ' > group-run-challenge.service - subscriptionToFinishedCompanyCitiesChallenges'
          );
        },
      }
    );
  }

  private getCompanyCitiesChallengeFinishedLastWeek(
    companyCitiesChallengesFinished: Array<CompanyCitiesChallenge>
  ): CompanyCitiesChallenge | null {
    const todayMinus7Days = Helpers.addDays(new Date(), -7);
    const todayMinus7DaysISO = Helpers.createISOEndDateTime(todayMinus7Days);

    const companyCitiesChallengesFinishedLastWeek = companyCitiesChallengesFinished.filter(
      (companyCitiesChallenge) =>
        companyCitiesChallenge.endDate >= todayMinus7DaysISO
    );

    return companyCitiesChallengesFinishedLastWeek.length > 0
      ? companyCitiesChallengesFinishedLastWeek[0]
      : null;
  }

  public getCompanyCitiesChallengeDailyPerformances(
    citiesChallengeId: string
  ): Promise<Array<CitiesChallengeDayPerformance>> {
    return this.firebaseService
      .getCompanyCitiesChallengeDailyPerformances(citiesChallengeId)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          return querySnapshot.docs.map(
            (docSnapshot) =>
              new CitiesChallengeDayPerformance(
                docSnapshot.data() as ICitiesChallengeDayPerformance
              )
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log('Error - getAllDailyPerformance: ', error);
        return [];
      });
  }

  public getCompanyCitiesChallengeParticipants(
    challengeId: string
  ): Promise<Array<ParticipantPerformance>> {
    return this.firebaseService
      .getCompanyCitiesChallengeParticipants(challengeId)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          return querySnapshot.docs.map(
            (docSnapshot) => new ParticipantPerformance(docSnapshot.data())
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log('Error - getAllDailyPerformance: ', error);
        return [];
      });
  }
}
