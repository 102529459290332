import {
  IParticipantPerformanceInitializer,
  ParticipantPerformance,
} from '../../participant-performance/participant-performance';

export interface IWeeklyHistory {
  id: string;
  teamsWeeklyPerformance: {
    [teamId: string]: IParticipantPerformanceInitializer;
  };
  weekdays: Array<string>;
}

export class WeeklyHistory {
  public id = '';
  public teamsWeeklyPerformance: Map<
    string,
    IParticipantPerformanceInitializer
  > = new Map();
  public weekdays: Array<string> = [];

  constructor(initializer?: Partial<IWeeklyHistory>) {
    if (initializer) {
      this.id = initializer.id ?? this.id;
      this.weekdays = initializer.weekdays ?? this.weekdays;
      this.teamsWeeklyPerformance = initializer.teamsWeeklyPerformance
        ? new Map<string, IParticipantPerformanceInitializer>(
            Object.entries(initializer.teamsWeeklyPerformance)
          )
        : this.teamsWeeklyPerformance;
    }
  }

  public toObject(): IWeeklyHistory {
    const { id, teamsWeeklyPerformance, weekdays } = this;

    const objectToReturn = {
      id,
      weekdays,
      teamsWeeklyPerformance: Object.fromEntries(teamsWeeklyPerformance),
    };

    return objectToReturn;
  }

  public sort(): Array<ParticipantPerformance> {
    const weeklyRanking = Array.from(this.teamsWeeklyPerformance.values()).map(
      (teamWeekly) => new ParticipantPerformance(teamWeekly)
    );

    weeklyRanking.sort((teamWeeklyA, teamWeeklyB) => {
      if (teamWeeklyB.average !== teamWeeklyA.average) {
        return teamWeeklyB.average - teamWeeklyA.average;
      } else if (teamWeeklyB.syncs !== teamWeeklyB.syncs) {
        return teamWeeklyB.syncs - teamWeeklyB.syncs;
      } else {
        return teamWeeklyB.performance - teamWeeklyA.performance;
      }
    });

    return weeklyRanking;
  }

  public isLastWeekday(isoDate: string): boolean {
    return (
      Boolean(isoDate) && this.weekdays[this.weekdays.length - 1] === isoDate
    );
  }

  public isCompleted(): boolean {
    return Array.from(this.teamsWeeklyPerformance.values()).some(
      (teamWeeklyPerformance) => teamWeeklyPerformance.points !== null
    );
  }
}
