<mat-nav-list [disableRipple]="true" class="sidebar">
  <div [ngClass]="sidebarOpened ? 'big-button' : 'small-button'"></div>

  <ng-container *ngIf="user">
    <!-- OVERVIEW -->
    <mat-list-item class="sidebar-item" routerLinkActive="active" routerLink=""
      [routerLinkActiveOptions]="{ exact: true }" (click)="closeCollapsibleItems()">
      <mat-icon matListItemIcon svgIcon="dashboard"></mat-icon>
      {{ 'OVERVIEW.TITLE' | translate }}
    </mat-list-item>

    <!-- USERS -->
    <mat-list-item class="sidebar-item" routerLinkActive="active" routerLink="users" *ngIf="user.role <= ADMIN_ROLE">
      <mat-icon matListItemIcon svgIcon="single-person"></mat-icon>
      {{ 'USERS.TITLE' | translate }}
    </mat-list-item>

    <!-- GROUP CHALLENGES -->
    <mat-list-item routerLinkActive="active" class="sidebar-item" (click)="challengesToggleSons()" *ngIf="user.role <= ADMIN_ROLE">
      <mat-icon matListItemIcon svgIcon="group-people"></mat-icon>
      {{ 'Challenges' | translate }}
      <mat-icon class="expander" matListItemIcon *ngIf="!openChallengesSons">expand_more</mat-icon>
      <mat-icon class="expander" matListItemIcon *ngIf="openChallengesSons">expand_less</mat-icon>
    </mat-list-item>
    <div  *ngIf="openChallengesSons ">
      <!-- DUELS -->
      <mat-list-item
        class="sidebar-item"
        routerLinkActive="active"
        routerLink="duels">
        <mat-icon style="visibility: hidden;">send</mat-icon>
        {{ 'DUEL_CHALLENGE.NAME' | translate }}
      </mat-list-item>
      <mat-divider class="divider-margin"></mat-divider>

      <!-- TEAM CHALLENGE -->
      <mat-list-item
        class="sidebar-item"
        routerLinkActive="active"
        routerLink="team-challenge"
        *ngIf="company && company.features.has(FEATURE_TEAM_CHALLENGES) && user.role < TEAM_LEADER_ROLE">
        <mat-icon style="visibility: hidden;">send</mat-icon>
        {{ 'TEAM_CHALLENGE.TITLE' | translate }}
      </mat-list-item>
      <mat-divider class="divider-margin"></mat-divider>

      <!-- TEAM CHALLENGE v2 -->
      <mat-list-item
        class="sidebar-item"
        routerLinkActive="active"
        [routerLink]="['team-challenges-v2', company.id, company.currentTeamChallenges.length > 0 ? company.currentTeamChallenges[0] : '']"
        *ngIf="company && company.features.has(FEATURE_TEAM_CHALLENGES) && company.id === 'Move Me' && user.role < TEAM_LEADER_ROLE">
        <mat-icon style="visibility: hidden;">send</mat-icon>
        {{ 'TEAM_CHALLENGE.TITLE' | translate }} V2
      </mat-list-item>
      <mat-divider class="divider-margin"></mat-divider>

      <!-- CITIES CHALLENGE -->
      <mat-list-item class="sidebar-item" routerLinkActive="active" [routerLink]="['cities-challenges', company.id, company.currentDistanceChallenge ? company.currentDistanceChallenge : '']"
        *ngIf="user.role <= ADMIN_ROLE">
        <mat-icon style="visibility: hidden;">send</mat-icon>
        {{ 'CITIES_CHALLENGE.TITLE' | translate }}
      </mat-list-item>
      <mat-divider class="divider-margin"></mat-divider>

      <!-- COMPANY CITIES CHALLENGE -->
      <mat-list-item class="sidebar-item" routerLinkActive="active" [routerLink]="['business-group-run-challenges', company.id]"
        *ngIf="user.role <= ADMIN_ROLE">
        <mat-icon style="visibility: hidden;">send</mat-icon>
        {{ 'COMPANY_CITIES_CHALLENGE.DASHBOARD.TITLE' | translate }}
      </mat-list-item>
      <mat-divider class="divider-margin"></mat-divider>

      <!-- EVENTS -->
      <mat-list-item class="sidebar-item" routerLinkActive="active" routerLink="events" *ngIf="user.role == MOVE_ME_ADMIN_ROLE">
        <mat-icon style="visibility: hidden;">send</mat-icon>
        {{ 'Events' | translate}}
      </mat-list-item>
    </div>

    <!-- MY TEAM  -->
    <mat-list-item class="sidebar-item" routerLinkActive="active" routerLink="my-team" *ngIf="
      user.role <= TEAM_LEADER_ROLE &&
      team &&
      user.teamId == team.id &&
      company?.currentTeamChallenges.length > 0"
      (click)="closeCollapsibleItems()">
      <mat-icon>diversity_2_outline</mat-icon>
      {{ 'MY_TEAM_TITLE' | translate }}
    </mat-list-item>

    <!-- ADMIN TEAMS -->
    <mat-list-item class="sidebar-item" routerLinkActive="active" routerLink="admin-teams" (click)="closeCollapsibleItems()"
      *ngIf="company?.currentTeamChallenges.length > 0  && user.role < TEAM_LEADER_ROLE">
      <mat-icon>workspaces_outline</mat-icon>
      {{ 'ADMIN_TEAMS_TEAMS' | translate }}
    </mat-list-item>

    <!-- PUSH NOTIFICATIONS -->
    <mat-list-item class="sidebar-item" routerLinkActive="active" routerLink="send-notification" *ngIf="
      user.role < TEAM_LEADER_ROLE ||
      (user.role === TEAM_LEADER_ROLE && user.teamId) ||
      user.role === PROJECT_MANAGER"
      (click)="closeCollapsibleItems()">
      <mat-icon svgIcon="arrow-send"></mat-icon>
      {{ 'forms.notification.PUSH_NOTIFICATION' | translate }}
    </mat-list-item>

    <!-- ADMIN -->
    <mat-list-item *ngIf="user.role === MOVE_ME_ADMIN_ROLE" class="sidebar-item" routerLinkActive="active"
      routerLink="admin" (click)="closeCollapsibleItems()">
      <mat-icon svgIcon="shield-person"></mat-icon>
      Admin
    </mat-list-item>

    <mat-divider ></mat-divider>

    <!-- REPORTS -->
    <mat-list-item class="sidebar-item" routerLinkActive="active" routerLink="reports" *ngIf="user.role === MOVE_ME_ADMIN_ROLE" (click)="closeCollapsibleItems()">
      <mat-icon svgIcon="clipboard"></mat-icon>
      {{ 'REPORTS' | translate}}
    </mat-list-item>

    <!-- PAYMENT -->
    <mat-list-item routerLinkActive="active" (click)="paymentToggleSons()" class="sidebar-item" *ngIf="user.role === MOVE_ME_ADMIN_ROLE">
      <mat-icon>credit_card</mat-icon>
      {{ 'Payment' | translate}}
      <mat-icon class="expander" *ngIf="!openPaymentSons">expand_more</mat-icon>
      <mat-icon class="expander" *ngIf="openPaymentSons">expand_less</mat-icon>
    </mat-list-item>

    <div *ngIf="openPaymentSons">
      <!-- SUBSCRIPTION PAGE -->
      <mat-list-item class="sidebar-item" (click)="goToSubscriptionPage()"  *ngIf="user.role <= MOVE_ME_ADMIN_ROLE">
        <mat-icon style="visibility: hidden;">credit_card</mat-icon>
        {{ 'Subscription' | translate}}
      </mat-list-item>
      <mat-divider class="divider-margin"></mat-divider>

      <!-- PRICING TABLE -->
      <mat-list-item class="sidebar-item" routerLinkActive="active" routerLink="pricing-table"  *ngIf="user.role <= MOVE_ME_ADMIN_ROLE">
        <mat-icon style="visibility: hidden;">table_chart</mat-icon>
        {{ 'Pricing Table' | translate}}
      </mat-list-item>
    </div>

    <!-- HELP -->
    <mat-list-item class="sidebar-item" routerLinkActive="active" routerLink="help" (click)="closeCollapsibleItems()">
      <mat-icon svgIcon="info-circle"></mat-icon>
      {{ 'HELP_TITLE' | translate }}
    </mat-list-item>
    <!-- SETTINGS -->
    <mat-list-item class="sidebar-item" routerLinkActive="active" routerLink="settings" (click)="closeCollapsibleItems()"
      *ngIf="user.role < TEAM_LEADER_ROLE || user.role === PROJECT_MANAGER">
      <mat-icon>settings</mat-icon>
      {{ 'SETTINGS.TITLE' | translate }}
    </mat-list-item>

  </ng-container>


  <!-- EXIT THE APP -->
  <mat-list-item class="sidebar-item" routerLinkActive="active"
    routerLink="exit_to_app" (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    {{ 'HEADER_LOGOUT' | translate }}
  </mat-list-item>

  <mat-list-item [disableRipple]="true" [disabled]="true">
    <p class="version-label">
      Version {{ appVersion }}
    </p>
  </mat-list-item>

</mat-nav-list>
