import { Helpers } from 'src/app/helpers/helpers';

export interface IParticipantPerformanceInitializer {
  id: string;
  avatar: string;
  name: string;
  performance: number;
  steps: number;
  mediumIntensityMinutes: number;
  highIntensityMinutes: number;
  count: number;
  bikeKm: number;
  syncs: number;
  swimMinutes: number;
  points: number | null;
  usersCount: number;
}

export class ParticipantPerformance {
  public id: string = '';
  public avatar: string = '';
  public name: string = '';
  public performance: number = 0;
  public steps: number = 0;
  public mediumIntensityMinutes: number = 0;
  public highIntensityMinutes: number = 0;
  public count: number = 0;
  public bikeKm: number = 0;
  public syncs: number = 0;
  public swimMinutes: number = 0;
  public points: number | null = null;
  public usersCount: number = 0;
  public average: number = 0;

  constructor(initializer?: Partial<IParticipantPerformanceInitializer>) {
    if (initializer) {
      this.id = initializer.id ?? this.id;
      this.avatar = initializer.avatar ?? this.avatar;
      this.name = initializer.name ?? this.name;
      this.performance = initializer.performance ?? this.performance;
      this.steps = initializer.steps ?? this.steps;
      this.mediumIntensityMinutes =
        initializer.mediumIntensityMinutes ?? this.mediumIntensityMinutes;
      this.highIntensityMinutes =
        initializer.highIntensityMinutes ?? this.highIntensityMinutes;
      this.count = initializer.count ?? this.count;
      this.bikeKm = initializer.bikeKm ?? this.bikeKm;
      this.syncs = initializer.syncs ?? this.syncs;
      this.swimMinutes = initializer.swimMinutes ?? this.swimMinutes;
      this.points = initializer.points ?? this.points;
      this.usersCount = initializer.usersCount ?? this.usersCount;
      this.average = Helpers.roundNumber(
        this.syncs > 0 ? this.performance / this.syncs : this.average,
        2
      );
    }
  }

  public toObject(): IParticipantPerformanceInitializer {
    return {
      id: this.id,
      avatar: this.avatar,
      name: this.name,
      performance: this.performance,
      steps: this.steps,
      mediumIntensityMinutes: this.mediumIntensityMinutes,
      highIntensityMinutes: this.highIntensityMinutes,
      count: this.count,
      bikeKm: this.bikeKm,
      syncs: this.syncs,
      points: this.points,
      usersCount: this.usersCount,
      swimMinutes: this.swimMinutes,
    };
  }
}
