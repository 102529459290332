import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import firebase from 'firebase/compat/app';

// Models
import { Company } from 'src/app/models/company.model';
import { SendNotificationType } from 'src/app/models/send-notification-type';
import { Team } from 'src/app/models/team.model';

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { REGEX_LINK } from 'src/app/constants/constants';
import { Notification } from 'src/app/models/notification.model';
import { GenericValidators } from 'src/app/validators/generic-validators/generic-validators';
import { UserMessageService } from '../../../services/user-message/user-message.service';

interface IFunctionsMap {
  [prop: string]: () => Promise<firebase.functions.HttpsCallableResult>;
}

@Component({
  selector: 'app-push-notifications-form',
  templateUrl: './push-notifications-form.component.html',
  styleUrls: ['./push-notifications-form.component.scss'],
})
export class PushNotificationsFormComponent implements OnChanges {
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;
  @Input() sendNotificationType: SendNotificationType;
  @Input() company: Company;
  @Input() possibleRecipients: Array<Company | Team> = [];

  public TITLE_CHARACTERS_LIMIT = 75;
  public BODY_CHARACTERS_LIMIT = 500;

  public notificationForm: UntypedFormGroup;

  public isSendingNotification = false;

  public SEND_NOTIFICATION_TYPE = SendNotificationType;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService,
    private userMessageService: UserMessageService
  ) {
    this.notificationForm = this.formBuilder.group({
      recipientGroup: [[]],
      titleMessageDe: [
        '',
        [
          Validators.required,
          GenericValidators.limitInput(this.TITLE_CHARACTERS_LIMIT),
        ],
      ],
      titleMessageEn: [''],
      notificationMessageDe: [
        '',
        [
          Validators.required,
          GenericValidators.limitInput(this.BODY_CHARACTERS_LIMIT),
        ],
      ],
      notificationMessageEn: [''],
      link: ['', Validators.compose([Validators.pattern(REGEX_LINK)])],
      deLanguage: [true],
      enLanguage: [false],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.possibleRecipients) {
      if (
        (changes.possibleRecipients.currentValue as Array<Company | Team>)
          .length > 0
      ) {
        Object.keys(this.notificationForm.controls).forEach((key) => {
          this.notificationForm.controls[key].enable();
        });
        if (
          this.sendNotificationType !== SendNotificationType.ProjectManager &&
          this.sendNotificationType !== SendNotificationType.MoveMeAdmin
        ) {
          this.notificationForm.controls.recipientGroup.setValue([
            this.possibleRecipients[0].id,
          ]);
          this.notificationForm.controls.recipientGroup.disable();
        }
      } else {
        Object.keys(this.notificationForm.controls).forEach((key) => {
          this.notificationForm.controls[key].disable();
        });
      }
    }
  }

  public sendNotification(): void {
    this.isSendingNotification = true;

    const titleDe = this.notificationForm.value.titleMessageDe
      ? this.notificationForm.value.titleMessageDe
      : this.notificationForm.value.titleMessageEn;
    const titleEn = this.notificationForm.value.titleMessageEn
      ? this.notificationForm.value.titleMessageEn
      : this.notificationForm.value.titleMessageDe;
    const messageDe = this.notificationForm.value.notificationMessageDe
      ? this.notificationForm.value.notificationMessageDe
      : this.notificationForm.value.notificationMessageEn;
    const messageEn = this.notificationForm.value.notificationMessageEn
      ? this.notificationForm.value.notificationMessageEn
      : this.notificationForm.value.notificationMessageDe;

    const notification = new Notification({
      id: '',
      read: false,
      title: {
        de: titleDe,
        en: titleEn,
      },
      message: {
        de: messageDe,
        en: messageEn,
      },
      link: this.notificationForm.value.link
        ? this.notificationForm.value.link
        : null,
      recipientCompanyIds: this.notificationForm.controls.recipientGroup.value,
    });

    const sendNotifications: IFunctionsMap = {
      [SendNotificationType.Team]: this.firebaseService.sendTeamLeaderNotification.bind(
        this,
        this.company.id,
        this.notificationForm.controls.recipientGroup.value,
        notification
      ),
      [SendNotificationType.Company]: this.firebaseService.sendAdminNotification.bind(
        this,
        notification,
        this.company.id
      ),
      [SendNotificationType.ProjectManager]: this.firebaseService.sendAdminNotification.bind(
        this,
        notification,
        this.company.id
      ),
      [SendNotificationType.MoveMeAdmin]: this.firebaseService.sendAdminNotification.bind(
        this,
        notification,
        this.company.id
      ),
    };

    sendNotifications[this.sendNotificationType]()
      .then(() => {
        this.userMessageService.snackBarMessage(
          'forms.notification.NOTIFICATION_SENT_SUCCESFULLY',
          'SUCCESS'
        );
      })
      .catch(() => {
        this.userMessageService.snackBarMessage(
          'forms.notification.NOTIFICATION_ERROR',
          'ERROR'
        );
      })
      .finally(() => {
        this.formDirective.resetForm({
          titleMessageDe: '',
          titleMessageEn: '',
          notificationMessageDe: '',
          notificationMessageEn: '',
          link: '',
          deLanguage: this.notificationForm.get('deLanguage')?.value,
          enLanguage: this.notificationForm.get('enLanguage')?.value,
          recipientGroup: this.notificationForm.get('recipientGroup')?.value,
        });
        this.isSendingNotification = false;
      });
  }

  public switchValidator(language: string): void {
    if (language === 'de') {
      this.notificationForm.controls.titleMessageDe.setValidators(
        this.notificationForm.get('deLanguage')?.value
          ? [
              Validators.required,
              GenericValidators.limitInput(this.TITLE_CHARACTERS_LIMIT),
            ]
          : null
      );
      this.notificationForm.controls.titleMessageDe.setValue('');
      this.notificationForm.controls.titleMessageDe.updateValueAndValidity();
      this.notificationForm.controls.notificationMessageDe.setValidators(
        this.notificationForm.get('deLanguage')?.value
          ? [
              Validators.required,
              GenericValidators.limitInput(this.BODY_CHARACTERS_LIMIT),
            ]
          : null
      );
      this.notificationForm.controls.notificationMessageDe.setValue('');
      this.notificationForm.controls.notificationMessageDe.updateValueAndValidity();
    }
    if (language === 'en') {
      this.notificationForm.controls.titleMessageEn.setValidators(
        this.notificationForm.get('enLanguage')?.value
          ? [
              Validators.required,
              GenericValidators.limitInput(this.TITLE_CHARACTERS_LIMIT),
            ]
          : null
      );
      this.notificationForm.controls.titleMessageEn.setValue('');
      this.notificationForm.controls.titleMessageEn.updateValueAndValidity();
      this.notificationForm.controls.notificationMessageEn.setValidators(
        this.notificationForm.get('enLanguage')?.value
          ? [
              Validators.required,
              GenericValidators.limitInput(this.BODY_CHARACTERS_LIMIT),
            ]
          : null
      );
      this.notificationForm.controls.notificationMessageEn.setValue('');
      this.notificationForm.controls.notificationMessageEn.updateValueAndValidity();
    }
  }
}
