<div class="container">
  <div fxLayoutAlign="end" fxLayout.xs="space-between space-around">
  </div>
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1 class="title" id="team-chalenge-title">{{ id }}</h1>
  </div>
  <div *ngIf="!showCompanySpinner">
    <div fxLayout="row" fxLayout.xs="column space-between" fxLayout.sm="column space-between"
      fxLayoutAlign="space-between center">
      <div class="title-row" fxLayout.xs="center">
        <img src="{{ company.avatar ? company.avatar : 'assets/logo_login.png' }}" alt="" />
      </div>
      <mat-card class="cards">
        <p class="card-title">
          {{ 'COMPANY_INFO_COMPANY_MEMBERS' | translate }}
        </p>
        <p class="card-number">{{ company.users.length }}</p>
      </mat-card>
      <mat-card class="cards">
        <p class="card-title">{{ 'COMPANY_INFO_TEAMS' | translate }}</p>
        <p class="card-number">{{ company.teamIds.length }}</p>
      </mat-card>
      <mat-card class="cards" (click)="updateCompanyStatus()">
        <p class="card-title">{{ 'Status' | translate }}</p>
        <p class="card-number">{{ statusEnumNames[company.status] }}</p>
      </mat-card>
      <mat-card class="cards" (click)="updateCompanyAvailableLicences()">
        <p class="card-title">
          {{ 'COMPANY_INFO_AVAILABLE_LICENSES' | translate }}
        </p>
        <p class="card-number">{{ company.availableLicenses }}</p>
      </mat-card>
      <mat-card class="cards" (click)="updateCompanyIsTestValue()">
        <p class="card-title">{{ 'Test Company' | translate }}</p>
        <p class="card-number">{{ company.isTestCompany }}</p>
      </mat-card>
    </div>

    <mat-tab-group (selectedTabChange)="tabChange($event)" [selectedIndex]="tabIndex">
      <mat-tab [label]="'Company Data'">
        <form [formGroup]="companyDataForm" (ngSubmit)="updateCompany($event)" class="company-info-form">
          <mat-form-field>
            <mat-label>{{ 'ADD_COMPANY_ID' | translate }}</mat-label>
            <input matInput required formControlName="companyId" />
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'ADD_COMPANY_NAME' | translate }}</mat-label>
            <input matInput required formControlName="name" />
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'ADD_COMPANY_ADMIN_EMAIL' | translate }}</mat-label>
            <mat-chip-list #chipList required>
              <mat-chip *ngFor="let email of adminEmails" [selectable]="selectable"
              [removable]="removable" (removed)="remove(email)">
              {{ email }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input type="email" formControlName="adminEmail"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)" />
              </mat-chip-list>
              <mat-error *ngIf="adminEmail.hasError('required') && adminEmail.touched">{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
              <mat-error *ngIf="adminEmail.hasError('email')">{{ 'ADD_COMPANY_ERROR_EMAIL' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'ADD_COMPANY_AVAILABLE_LICENCES' | translate }}</mat-label>
            <input matInput required type="number" formControlName="availableLicenses" />
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'CREATE_COMPANY.PROJECT_MANAGER_FORM.MANAGER_COMPANY' | translate }} ({{'forms.OPTIONAL' | translate}}) </mat-label>
            <mat-select multiple formControlName="managerCompanyIds">
              <mat-option *ngFor="let company of managerCompanies" [value]="company.id">{{ company.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="color-input">
            <mat-label>{{ 'ADD_COMPANY_COLOR' | translate }}</mat-label>
            <input matInput formControlName="color" (colorPickerChange)="onChangeCompanyColor($event)" [(colorPicker)]="this.companyDataForm.value.color" [style.background]="this.companyDataForm.value.color"/>
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <ngx-mat-file-input
              formControlName="avatarImg"
              [placeholder]="'ADD_COMPANY_AVATAR_PLACEHOLDER' | translate"
            ></ngx-mat-file-input>
            <mat-icon matSuffix>attach_file</mat-icon>
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>

          <h2>{{ 'ADD_COMPANY_EXTRA_FEATURES' | translate }}</h2>
          <mat-checkbox color="primary" formControlName="featureTeamChallenge">{{
            'ADD_COMPANY_TEAM_CHALLENGES' | translate
          }}</mat-checkbox>
          <mat-checkbox color="primary" formControlName="featureMarketplace">{{
            'ADD_COMPANY_MARKETPLACE' | translate
          }}</mat-checkbox>
          <mat-checkbox color="primary" formControlName="featureWhiteLabel">{{
            'ADD_COMPANY_WHITE_LABEL' | translate
          }}</mat-checkbox>
          <mat-checkbox color="primary" formControlName="featureProjectManager">{{
            'CREATE_COMPANY.PROJECT_MANAGER_FORM.PROJECT_MANAGER' | translate
          }}</mat-checkbox>
          <mat-checkbox color="primary" formControlName="featureSetAutomaticTeam">{{
            'ADD_COMPANY_SET_AUTOMATIC_TEAM' | translate
          }}</mat-checkbox>
          <mat-checkbox color="primary" formControlName="featureNotSetAutomaticTeam">{{
            'ADD_COMPANY_NOT_SET_AUTOMATIC_TEAM' | translate
          }}</mat-checkbox>
          <mat-checkbox color="primary" formControlName="featureNotShowWhiteLabelLogoOnReport">{{
            'ADD_COMPANY_NOT_SHOW_WHITE_LABEL_LOGO_ON_REPORT' | translate
          }}</mat-checkbox>
          <mat-checkbox color="primary" formControlName="featureOrganisationBoard">{{
            'ADD_COMPANY_SET_ORGANISATION_BOARD' | translate
          }}</mat-checkbox>
          <mat-checkbox color="primary" formControlName="featureEvents">{{
            'ADD_COMPANY_SET_EVENTS' | translate
          }}</mat-checkbox>
          <mat-checkbox color="primary" formControlName="featureJoinTeamCard">{{
            'ADD_COMPANY_SET_JOIN_TEAM_CARD' | translate
          }}</mat-checkbox>
          <mat-checkbox color="primary" formControlName="featureLimitedLicenses">{{
            'ADD_COMPANY_SET_LIMITED_LICENSES' | translate
          }}</mat-checkbox>
          <mat-checkbox color="primary" formControlName="featureShareEmail">{{
            'ADD_COMPANY_SET_SHARE_EMAIL' | translate
          }}</mat-checkbox>
          <mat-checkbox color="primary" formControlName="featureReferralProgramDisabled">{{
            'ADD_COMPANY_SET_DISABLE_REFERRAL_PROGRAM' | translate
          }}</mat-checkbox>

          <mat-form-field appearance="fill">
            <mat-label>{{ 'ADD_COMPANY_SET_UNIT_CONVERTER' | translate }}</mat-label>
            <mat-select formControlName="featureUnitConverter" multiple>
              <mat-option *ngFor="let unitConverterOption of unitConverterList" [value]="unitConverterOption">{{ unitConverterOption.name | translate }}</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="white-label-container" *ngIf="this.companyDataForm.controls['featureWhiteLabel'].value">
            <mat-form-field>
              <mat-label>{{ 'Select a White Label' | translate }}</mat-label>
              <mat-select [(value)]="whiteLabelSelected" (selectionChange)="updateWhiteLabelValues()">
                <mat-option
                  *ngFor="let whiteLabel of whiteLabels; let i = index"
                  [value]="whiteLabel"
                >
                  {{ whiteLabel.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="whiteLabelImgPreview" class="white-label-logo-container">
              <img [src]="whiteLabelImgPreview" />
            </div>
            <mat-form-field>
              <ngx-mat-file-input
                accept="image/jpeg, image/jpg, image/png"
                formControlName="whiteLabelLogo"
                [placeholder]="'ADD_COMPANY_AVATAR_WHITE_LABEL' | translate"
              ></ngx-mat-file-input>
              <mat-icon matSuffix>attach_file</mat-icon>
              <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field class="color-input">
              <mat-label>{{ 'ADD_COMPANY_WHITE_LABEL_BUTTON_COLOR' | translate }}</mat-label>
              <input matInput formControlName="whiteLabelButtonColor" (colorPickerChange)="onChangeWhiteLabelButtonColor($event)" [(colorPicker)]="this.companyDataForm.value.whiteLabelButtonColor" [style.background]="this.companyDataForm.value.whiteLabelButtonColor"/>
              <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'ADD_COMPANY_WHITE_LABEL_BUTTON_TEXT' | translate }}</mat-label>
              <input matInput formControlName="whiteLabelButtonText" />
              <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'ADD_COMPANY_WHITE_LABEL_BUTTON_LINK' | translate }}</mat-label>
              <input matInput formControlName="whiteLabelButtonLink" />
              <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <mat-checkbox color="primary" formControlName="featureLocations">{{
            'ADD_COMPANY_LOCATIONS' | translate
          }}</mat-checkbox>
          <div *ngIf="this.companyDataForm.controls['featureLocations'].value">
            <form class="new-location-form" [formGroup]="locationDataForm">
              <div class="new-location-form-title">
                <h2>
                  {{ 'Location Levels' | translate }}
                </h2>
              </div>
              <div class="new-location-form-levels-selection">
                <mat-radio-group color="primary" formControlName="levels" class="example-radio-group">
                  <mat-radio-button class="example-radio-button" [value]="1">1</mat-radio-button>
                  <mat-radio-button class="example-radio-button" [value]="2">2</mat-radio-button>
                  <mat-radio-button class="example-radio-button" [value]="3">3</mat-radio-button>
                </mat-radio-group>
              </div>

              <div *ngFor="let level of [1, 2, 3]">
                <form class="new-location-form" [formGroup]="levelsDataForms[level]" *ngIf="locationDataForm.value.levels > level - 1">
                  <div class="new-location-form-location-level-title">
                    <h2 class="location-level-title">
                      Location Level {{ level }}
                    </h2>
                  </div>

                  <div class="new-location-form-location-level-subtitle">
                    <span class="location-level-subtitle">
                      Title
                    </span>
                  </div>

                  <mat-form-field>
                    <mat-label>{{ 'languages.en' | translate }}</mat-label>
                    <input type="text" matInput formControlName="titleEN" />
                    <mat-error>{{ 'Error' | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{ 'languages.de' | translate }}</mat-label>
                    <input type="text" matInput formControlName="titleDE" />
                    <mat-error>{{ 'Error' | translate }}</mat-error>
                  </mat-form-field>

                  <div class="new-location-form-location-level-subtitle">
                    <span class="location-level-subtitle">
                      Selector Label
                    </span>
                  </div>

                  <mat-form-field>
                    <mat-label>{{ 'languages.en' | translate }}</mat-label>
                    <input type="text" matInput formControlName="selectorEN" />
                    <mat-error>{{ 'Error' | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{ 'languages.de' | translate }}</mat-label>
                    <input type="text" matInput formControlName="selectorDE" />
                    <mat-error>{{ 'Error' | translate }}</mat-error>
                  </mat-form-field>

                  <div class="new-location-form-location-level-subtitle">
                    <span class="location-level-subtitle">
                      Locations
                    </span>
                  </div>

                  <mat-form-field>
                    <mat-label>{{ 'Name' | translate }}</mat-label>
                    <input type="text" matInput formControlName="name" />
                    <mat-error>{{ 'Error' | translate }}</mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{ 'ID' | translate }}</mat-label>
                    <input type="text" matInput formControlName="id" />
                    <mat-error>{{ 'Error' | translate }}</mat-error>
                  </mat-form-field>
                  <button
                    mat-raised-button
                    (click)="isEditActive ? updateLocation(level, $event) : addLocationToLevel(level)"
                  >
                    {{ isEditActive && editLevel === level ? 'Update Location' : 'Add Location' | translate }}
                  </button>
                  <button
                    *ngIf="isEditActive && editLevel === level"
                    mat-raised-button
                    (click)="cancelEdit(level)"
                  >
                    {{ 'Cancel' | translate }}
                  </button>

                  <table
                    #tableLocationLevel
                    mat-table
                    [dataSource]="levelsDataSources[level]"
                    matSort
                    *ngIf="levelsLocations[level].length !== 0"
                  >
                    <!-- Id Column -->
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                      <td mat-cell *matCellDef="let location">
                        {{ location.id }}
                      </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                      <td mat-cell *matCellDef="let location">
                        {{ location.name }}
                      </td>
                    </ng-container>

                    <!-- Edit Column -->
                    <ng-container matColumnDef="edit">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                      <td
                        mat-cell
                        *matCellDef="let location; let i = index"
                        (click)="editLocation(location.id, level)"
                        class="clickable-column"
                      >
                        <mat-icon>edit</mat-icon>
                      </td>
                    </ng-container>

                    <!-- Links Column -->
                    <ng-container matColumnDef="links">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                      <td
                        mat-cell
                        *matCellDef="let location; let i = index"
                        (click)="linkLocation(location, level)"
                        class="clickable-column"
                      >
                        <mat-icon [ngClass]="locationDataForm.value.levels !== level ? 'enabled' : 'disabled'">share</mat-icon>
                      </td>
                    </ng-container>

                    <!-- Delete Column -->
                    <ng-container matColumnDef="delete">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td
                        mat-cell
                        *matCellDef="let location; let i = index"
                        (click)="deleteLocation(location, level, $event)"
                        class="clickable-column"
                      >
                        <mat-icon>delete_outline</mat-icon>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="locationColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: locationColumns"></tr>
                  </table>

                </form>
              </div>
            </form>
          </div>
          <div class="buttons">
            <button mat-raised-button [disabled]="!companyDataForm.valid || adminEmails.length === 0">
              {{ 'Update Company' | translate }}
              <mat-spinner [diameter]="20" *ngIf="showUpdateCompanySpinner"></mat-spinner>
            </button>
          </div>
        </form>
        <button mat-raised-button role="button" (click)="cleanProjectData()">
          {{ 'COMPANY_INFO_PAGE.ALERTS.CLEAN_COMPANY_PROJECT_CONFIRMATION.TITLE' | translate }}
        </button>

      </mat-tab>
      <ng-container *ngIf="company.features.has(FEATURES_PROJECT_MANAGER)">
        <mat-tab [label]="'Push Notifications'">
          <div class="spinner-container" *ngIf="showPushNotificationsSpinner">
            <mat-spinner></mat-spinner>
          </div>
          <app-sent-push-notifications-table *ngIf="pushNotifications && pushNotifications.length > 0 && !showPushNotificationsSpinner" [pushNotifications]="pushNotifications"></app-sent-push-notifications-table>
          <div class="no-push-notification-message-container" *ngIf="pushNotifications && pushNotifications.length === 0 && !showPushNotificationsSpinner">
            <h1 class="no-push-notification-message">{{'COMPANY_INFO.NO_PUSH_NOTIFICATION_MESSAGE' | translate }}</h1>
          </div>
        </mat-tab>
        <mat-tab [label]="'Child Companies'">
          <div class="spinner-container" *ngIf="showManagedCompaniesSpinner">
            <mat-spinner></mat-spinner>
          </div>
          <app-managed-companies-table [companies]="managedCompanies" [companiesStatistics]="companiesStatistics" [managedCompanyChallengesData]="managedCompanyChallengesData"
            *ngIf="managedCompanies && managedCompanies.length > 0 && !showManagedCompaniesSpinner"></app-managed-companies-table>
          <div class="no-managed-companies-message-container" *ngIf="managedCompanies && managedCompanies.length === 0 && !showManagedCompaniesSpinner">
            <h1 class="no-managed-companies-message">{{ 'COMPANY_INFO.NO_MANAGED_COMPANIES_MESSAGE' | translate }}</h1>
          </div>
        </mat-tab>
      </ng-container>
      <ng-container *ngIf="!company.features.has(FEATURES_PROJECT_MANAGER)" >
        <mat-tab [label]="'Personal Challenges'">
          <div class="spinner-container" *ngIf="showPersonalChallengesSpinner">
            <mat-spinner></mat-spinner>
          </div>
          <div *ngIf="!showPersonalChallengesSpinner">
            <button mat-raised-button (click)="goToAddPersonalChallenge()">
              New Personal Challenge
            </button>
            <table mat-table [dataSource]="personalChallengesDataSource" matSort>
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let personalChallenge">
                  {{ personalChallenge.name }}
                </td>
              </ng-container>

              <!-- Fixed Date Column -->
              <ng-container matColumnDef="fixedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Fixed Date?
                </th>
                <td mat-cell *matCellDef="let personalChallenge">
                  {{ personalChallenge.hasFixedDate }}
                </td>
              </ng-container>

              <!-- Start Date Column -->
              <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Start Date
                </th>
                <td mat-cell *matCellDef="let personalChallenge">
                  {{
                  personalChallenge.hasFixedDate
                  ? (personalChallenge.startDate | date: 'dd.MM.yyyy')
                  : '-'
                  }}
                </td>
              </ng-container>

              <!-- End Date Column -->
              <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  End Date
                </th>
                <td mat-cell *matCellDef="let personalChallenge">
                  {{
                  personalChallenge.hasFixedDate
                  ? (personalChallenge.endDate | date: 'dd.MM.yyyy')
                  : '-'
                  }}
                </td>
              </ng-container>

              <!-- Initial Level Column -->
              <ng-container matColumnDef="initialLevel">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Initial Level
                </th>
                <td mat-cell *matCellDef="let personalChallenge">
                  {{ personalChallenge.initialLevel }}
                </td>
              </ng-container>

              <!-- Minimal Level Column -->
              <ng-container matColumnDef="minLevel">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Min Level
                </th>
                <td mat-cell *matCellDef="let personalChallenge">
                  {{ personalChallenge.minLevel }}
                </td>
              </ng-container>

              <!-- Maximal Level Column -->
              <ng-container matColumnDef="maxLevel">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Max Level
                </th>
                <td mat-cell *matCellDef="let personalChallenge">
                  {{ personalChallenge.maxLevel }}
                </td>
              </ng-container>

              <!-- Level Duration Column -->
              <ng-container matColumnDef="levelDuration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Level Duration
                </th>
                <td mat-cell *matCellDef="let personalChallenge">
                  {{ personalChallenge.levelDuration + ' days' }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="personalChallengeColumns"></tr>
              <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: personalChallengeColumns"
                (click)="goToPersonalChallenge(row)"></tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab [label]="'Points Sources'">
          <div class="spinner-container" *ngIf="showPointsSourcesSpinner">
            <mat-spinner></mat-spinner>
          </div>
          <div *ngIf="!showPointsSourcesSpinner">
            <div>
              <button mat-raised-button (click)="goToAddPointsSource()">
                New Point Source
              </button>
            </div>
            <table mat-table [dataSource]="pointsSourceDataSource" matSort>
              <!-- Id Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
                <td mat-cell *matCellDef="let pointsSource">
                  {{ pointsSource.id }}
                </td>
              </ng-container>

              <!-- Description Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Description
                </th>
                <td mat-cell *matCellDef="let pointsSource">
                  {{ pointsSource.description }}
                </td>
              </ng-container>

              <!-- Basis Column -->
              <ng-container matColumnDef="basis">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Basis</th>
                <td mat-cell *matCellDef="let pointsSource">
                  {{ pointsSourceBasisNames[pointsSource.basis] }}
                </td>
              </ng-container>

              <!-- Expected Performance Column -->
              <ng-container matColumnDef="expectedPerformance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Expected Performance
                </th>
                <td mat-cell *matCellDef="let pointsSource">
                  {{ pointsSource.expectedPerformance }}
                </td>
              </ng-container>

              <!-- Type Column -->
              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                <td mat-cell *matCellDef="let pointsSource">
                  {{ pointsSourceTypesNames[pointsSource.type] }}
                </td>
              </ng-container>

              <!-- Points Awarded Column -->
              <ng-container matColumnDef="pointsAwarded">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Points Awarded
                </th>
                <td mat-cell *matCellDef="let pointsSource">
                  {{ pointsSource.pointsAwarded }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="pointsSourceColumns"></tr>
              <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: pointsSourceColumns"
                (click)="goToPointsSource(row)"></tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab [label]="'Teams'">
          <div class="spinner-container" *ngIf="showTeamSpinner">
            <mat-spinner></mat-spinner>
          </div>
          <div *ngIf="!showTeamSpinner">
            <div>
              <button mat-raised-button (click)="goToAddTeam()">
                {{ 'ADMIN_NEW_TEAM_BUTTON' | translate }}
              </button>
              <button mat-raised-button (click)="openRandomTeamsDialog()">
                {{ 'Add Random Teams' | translate }}
              </button>
            </div>
            <div *ngIf="showRandomTeamsTable">
              <h2>New Teams to Add</h2>
              <table mat-table [dataSource]="randomTeamsDataSource" matSort>
                <!-- Avatar Column -->
                <ng-container matColumnDef="avatar">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let team" class="avatar">
                    <img *ngIf="team.avatar !== 'assets/img/move-me-new.png'" src="{{ team.avatar }}" alt="avatar" />
                    <div *ngIf="team.avatar === 'assets/img/move-me-new.png'">
                      <mat-icon>do_not_disturb</mat-icon>
                    </div>
                  </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                  <td mat-cell *matCellDef="let team">{{ team.name }}</td>
                </ng-container>

                <!-- Id Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
                  <td mat-cell *matCellDef="let team">{{ team.id }}</td>
                </ng-container>

                <!-- Id Column -->
                <ng-container matColumnDef="color">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Color</th>
                  <td mat-cell *matCellDef="let team" [ngStyle]="{ 'color': team.color }">{{ team.color }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="randomTeamsColumns"></tr>
                <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: randomTeamsColumns"></tr>
              </table>
              <div>
                <button mat-raised-button (click)="addRandomTeams()">
                  {{ 'Add New Teams' | translate }}
                </button>
              </div>
            </div>
            <table mat-table [dataSource]="teamsDataSource" matSort>
              <!-- Avatar Column -->
              <ng-container matColumnDef="avatar">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let team" class="avatar">
                  <img *ngIf="team.avatar !== 'assets/img/move-me-new.png'" src="{{ team.avatar }}" alt="avatar" />
                  <div *ngIf="team.avatar === 'assets/img/move-me-new.png'">
                    <mat-icon>do_not_disturb</mat-icon>
                  </div>
                </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let team">{{ team.name }}</td>
              </ng-container>

              <!-- Members Column -->
              <ng-container matColumnDef="members">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Members</th>
                <td mat-cell *matCellDef="let team">{{ team.members.size }}</td>
              </ng-container>

              <!-- Points Column -->
              <ng-container matColumnDef="totalPoints">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Total Points
                </th>
                <td mat-cell *matCellDef="let team">{{ team.totalPoints }}</td>
              </ng-container>

              <!-- Steps Column -->
              <ng-container matColumnDef="totalSteps">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Total Steps
                </th>
                <td mat-cell *matCellDef="let team">{{ team.totalSteps }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="teamsColumns"></tr>
              <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: teamsColumns" (click)="goToTeam(row)"></tr>
            </table>
          </div>
        </mat-tab>

        <mat-tab [label]="'Team Challenges'">
          <div class="spinner-container" *ngIf="showTeamChallengesSpinner">
            <mat-spinner></mat-spinner>
          </div>
          <div *ngIf="!showTeamChallengesSpinner">
            <div>
              <button mat-raised-button (click)="goToAddTeamChallenge()">
                New Team Challenge
              </button>
            </div>
            <table mat-table [dataSource]="teamChallengesDataSource" matSort>
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let teamChallenge">
                  {{ teamChallenge.name }}
                </td>
              </ng-container>

              <!-- Start Date Column -->
              <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Start Date
                </th>
                <td mat-cell *matCellDef="let teamChallenge">
                  {{ teamChallenge.startDate | date: 'dd.MM.yyyy' }}
                </td>
              </ng-container>

              <!-- End Date Column -->
              <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  End Date
                </th>
                <td mat-cell *matCellDef="let teamChallenge">
                  {{ teamChallenge.endDate | date: 'dd.MM.yyyy' }}
                </td>
              </ng-container>

              <!-- Current Week Column -->
              <ng-container matColumnDef="currentWeek">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Current Week
                </th>
                <td mat-cell *matCellDef="let teamChallenge">
                  {{ teamChallenge.currentWeek }}
                </td>
              </ng-container>

              <!-- Duration Column -->
              <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Duration
                </th>
                <td mat-cell *matCellDef="let teamChallenge">
                  {{ teamChallenge.duration + ' weeks' }}
                </td>
              </ng-container>

              <!-- Teams Amount Column -->
              <ng-container matColumnDef="teamsAmount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Number of Teams
                </th>
                <td mat-cell *matCellDef="let teamChallenge">
                  {{ teamChallenge.teamIds.size }}
                </td>
              </ng-container>

              <!-- Project Plan Column -->
              <ng-container matColumnDef="projectPlan">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Project Plan
                </th>
                <td mat-cell *matCellDef="let teamChallenge">
                  <button mat-raised-button #projectPlanButton (click)="sendProjectPlan(teamChallenge, projectPlanButton)">
                    <mat-spinner diameter="20" *ngIf="projectPlanButton.disabled"></mat-spinner>
                    <mat-icon *ngIf="!projectPlanButton.disabled">outgoing_mail</mat-icon>
                  </button>
                </td>
              </ng-container>

              <!-- Edit Column -->
              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                </th>
                <td mat-cell *matCellDef="let teamChallenge">
                  <button mat-raised-button (click)="goToTeamChallenge(teamChallenge)">
                    <mat-icon>
                      edit
                    </mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="teamChallengeColumns"></tr>
              <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: teamChallengeColumns"></tr>
            </table>
          </div>
        </mat-tab>

        <mat-tab [label]="'COMPANY_INFO.TEAMS_CHALLENGE.TITLE' | translate">
          <div class="spinner-container" *ngIf="showTeamChallengesV2Spinner">
            <mat-spinner></mat-spinner>
          </div>
          <div *ngIf="!showTeamChallengesV2Spinner">
            <div>
              <button mat-raised-button (click)="goToNewTeamChallengePage()">
                {{ 'COMPANY_INFO.TEAMS_CHALLENGE.NEW_BUTTON' | translate }}
              </button>
            </div>
            <app-team-challenge-table [challenges]="teamChallengesV2" [company]="company"></app-team-challenge-table>
          </div>
        </mat-tab>
        <mat-tab [label]="'COMPANY_INFO.CITIES_CHALLENGE.TITLE' | translate">
          <div class="spinner-container" *ngIf="showCitiesChallengesSpinner">
            <mat-spinner></mat-spinner>
          </div>
          <div *ngIf="!showCitiesChallengesSpinner">
            <div>
              <button mat-raised-button (click)="goToNewCitiesChallenge()">
                {{ 'COMPANY_INFO.CITIES_CHALLENGE.NEW_BUTTON' | translate }}
              </button>
            </div>

            <app-cities-challenge-table [challenges]="citiesChallenges" [company]="company"></app-cities-challenge-table>
          </div>
        </mat-tab>
        <!-- EVENTS -->
        <mat-tab [label]="'COMPANY_INFO.EVENT.TITLE' | translate">
          <div class="spinner-container" *ngIf="showEventsSpinner">
            <mat-spinner></mat-spinner>
          </div>
          <div *ngIf="!showEventsSpinner">
            <div>
              <button mat-raised-button (click)="goToAddEvent()">
              {{'COMPANY_INFO.EVENT.NEW_EVENT' | translate}}
              </button>
            </div>
          </div>
            <table mat-table #tableEvents [dataSource]="eventsDataSource" matSort>
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMPANY_INFO.EVENT.EVENT_NAME' | translate}}</th>
                <td mat-cell *matCellDef="let event">
                  {{ event.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{'COMPANY_INFO.EVENT.START_DATE' | translate}}
                </th>
                <td mat-cell *matCellDef="let event">
                  {{ event.days[0]}}
                </td>
              </ng-container>
              <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{'COMPANY_INFO.EVENT.END_DATE' | translate}}
                </th>
                <td mat-cell *matCellDef="let event">
                  {{event.days.slice(-1)[0] }}
                </td>
              </ng-container>
              <!-- Distance Column -->
              <ng-container matColumnDef="distance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Distance in Km
                </th>
                <td mat-cell *matCellDef="let event">
                  {{ event.distance }}
                </td>
              </ng-container>
              <!-- Progress Progress -->
              <ng-container matColumnDef="progress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Progress in Km
                </th>
                <td mat-cell *matCellDef="let event">
                  {{ event.progress }}
                </td>
              </ng-container>
              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Status
                </th>
                <td mat-cell *matCellDef="let event">
                  {{ event.status === EVENT_NOT_STARTED ?
                    ("ADMIN_PAGE.EVENTS_TAB.STATUS.NOT_STARTED" | translate) :
                    event.status === EVENT_IN_PROGRESS ?
                      ("ADMIN_PAGE.EVENTS_TAB.STATUS.IN_PROGRESS" | translate) :
                      ("ADMIN_PAGE.EVENTS_TAB.STATUS.COMPLETED" | translate)  }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="eventsColumns"></tr>
              <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: eventsColumns"
              (click)="row.status === 0 ? goToEditEvent(row) : null"></tr>
            </table>
        </mat-tab>
        <mat-tab [label]="'COMPANY_INFO.DUEL_CHALLENGE_VIEW.TITLE' | translate">
          <app-duels *ngIf="currentDuelChallengeTabLoaded" [company]="company"></app-duels>
        </mat-tab>
        <mat-tab [label]="'COMPANY_INFO.CITIES_CHALLENGE_VIEW.TITLE' | translate">
          <app-cities-challenge-container *ngIf="currentCitiesChallengeTabLoaded" [companyId]="company.id" [challengeId]="company.currentDistanceChallenge"></app-cities-challenge-container>
        </mat-tab>
        <mat-tab [label]="'Current Team challenges'">
          <app-team-challenge-component [company]="company" [currentTeamChallengeWeeks]="currentTeamChallengeWeeks"
          [currentTeamChallengeData]="currentTeamChallengeData"></app-team-challenge-component>
        </mat-tab>
        <mat-tab [label]="'COMPANY_INFO.COMPANY_CITIES_CHALLENGE_VIEW.TITLE' | translate">
          <app-company-cities-challenge-container *ngIf="currentCompanyCitiesChallengeTabLoaded" [companyId]="company.id"></app-company-cities-challenge-container>
        </mat-tab>
        <mat-tab [label]="'Users per Location'" *ngIf="company.features.has(FEATURES_LOCATIONS) && !company.features.has(FEATURES_PROJECT_MANAGER)">
          <div class="spinner-container" *ngIf="usersLocationsListShowSpinner">
            <mat-spinner></mat-spinner>
          </div>
          <div *ngIf="!usersLocationsListShowSpinner">
            <app-users-list [locations]="locations" [companyUsers]="company.users.length"></app-users-list>
          </div>
        </mat-tab>
        <mat-tab [label]="'Excel Report'">
          <div>
            <h3>Users steps report</h3>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'OVERVIEW.DATE_SELECTION' | translate }}</mat-label>
              <mat-date-range-input [rangePicker]="picker" [formGroup]="reportDateRange" (click)="picker.open()">
                <input matStartDate placeholder="{{ 'DATE_PLACEHOLDER_START' | translate }}" formControlName="start" />
                <input matEndDate placeholder="{{ 'DATE_PLACEHOLDER_END' | translate }}" formControlName="end" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <button mat-raised-button color="primary" [disabled]="
                !reportDateRange.value.start ||
                !reportDateRange.value.end ||
                disableButton
              " (click)="export()">
              <div class="download-button">
                {{
                this.spinnerFlag
                ? ('OVERVIEW.DOWNLOADING' | translate)
                : ('OVERVIEW.DOWNLOAD' | translate)
                }}
                <mat-spinner diameter="20" *ngIf="spinnerFlag"></mat-spinner>
                <mat-icon *ngIf="!spinnerFlag">download</mat-icon>
              </div>
            </button>
          </div>
          <div>
            <h3>Team users steps report</h3>
            <button mat-raised-button color="primary" [disabled]="usersTeamReportSpinnerFlagDisableButton" (click)="usersTeamReport()">
              <div class="download-button">
                {{
                this.spinnerFlag
                ? ('OVERVIEW.DOWNLOADING' | translate)
                : ('OVERVIEW.DOWNLOAD' | translate)
                }}
                <mat-spinner diameter="20" *ngIf="usersTeamReportSpinnerFlag"></mat-spinner>
                <mat-icon *ngIf="!spinnerFlag">download</mat-icon>
              </div>
            </button>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
  <div class="spinner-container" *ngIf="showCompanySpinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
