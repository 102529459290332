import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CardComponent } from './widgets/card/card.component';
import { StepsComponent } from './widgets/steps/steps.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { HighchartsChartModule } from 'highcharts-angular';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeamChallengesComponent } from 'src/app/shared/components/team-challenge/team-challenge.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { UsersListComponent } from './components/users-list/users-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CityLinkDialogComponent } from './components/city-link-dialog/city-link-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { CompanyStatusDialogComponent } from './components/company-status-dialog/company-status-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AddRandomTeamsDialogComponent } from './components/add-random-teams-dialog/add-random-teams-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EditCityDialogComponent } from './components/edit-city-dialog/edit-city-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NotificationMessagesComponent } from './components/notification-messages/notification-messages.component';
import { UpdatePropertyDialogComponent } from './components/update-property-dialog/update-property-dialog.component';
import { AddCityDialogComponent } from './components/add-city-dialog/add-city-dialog.component';
import { CompanyIsTestDialogComponent } from './components/company-is-test-dialog/company-is-test-dialog.component';
// eslint-disable-next-line max-len
import { CompanyUpdateAvailableLicencesComponent } from './components/company-update-available-licences/company-update-available-licences.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { LinkLocationsDialogComponent } from './components/link-locations-dialog/link-locations-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NewPersonalChallengeDialogComponent } from './components/new-personal-challenge-dialog/new-personal-challenge-dialog.component';
import { NewTeamChallengeDialogComponent } from './components/new-team-challenge-dialog/new-team-challenge-dialog.component';
import { PricingTableComponent } from './components/pricing-table/pricing-table.component';
// eslint-disable-next-line max-len
import { ImportRouteConfirmationDialogComponent } from './components/import-route-confirmation-dialog/import-route-confirmation-dialog.component';
import { SelectPropertyDialogComponent } from './components/select-property-dialog/select-property-dialog.component';
import { CompaniesTableComponent } from './components/companies-table/companies-table.component';
import { SentPushNotificationsTableComponent } from './components/sent-push-notifications-table/sent-push-notifications-table.component';
import { PushNotificationsFormComponent } from './components/push-notifications-form/push-notifications-form.component';
import { ManagedCompaniesTableComponent } from './components/managed-companies-table/managed-companies-table.component';
import { NewEmailFormComponent } from './components/new-email-form/new-email-form.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { EditImageAndNameCardComponent } from './components/edit-image-and-name-card/edit-image-and-name-card.component';
import { CityInfoCardComponent } from './components/city-info-card/city-info-card.component';
import { EditCityInfoDialogComponent } from './components/edit-city-info-dialog/edit-city-info-dialog.component';
import { RoutesTableComponent } from './components/routes-table/routes-table.component';
import { RouteFormComponent } from './components/route-form/route-form.component';
import { CitiesChallengeTableComponent } from './components/cities-challenge-table/cities-challenge-table.component';
import { ChallengeTypeCardComponent } from './components/challenge-type-card/challenge-type-card.component';
import { ChallengeTypeSelectionComponent } from './components/challenge-type-selection/challenge-type-selection.component';
import { CitiesChallengeFormComponent } from './components/cities-challenge-form/cities-challenge-form.component';
import { RegionFormComponent } from './components/region-form/region-form.component';
import { MatStepperModule } from '@angular/material/stepper';
import { CitiesChallengeOverviewComponent } from './components/cities-challenge-overview/cities-challenge-overview.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AddCitiesChallengeComponent } from '../pages/admin/add-cities-challenge/add-cities-challenge.component';
import { ChallengeCountdownComponent } from './components/challenge-countdown/challenge-countdown.component';
// eslint-disable-next-line max-len
import { CitiesChallengeStartsSoonCardComponent } from './components/cities-challenge-starts-soon-card/cities-challenge-starts-soon-card.component';
import { ChallengeHeaderComponent } from './widgets/challenge-header/challenge-header.component';
import { BadgeCardComponent } from './widgets/badge-card/badge-card.component';
import { KpiListComponent } from './widgets/kpi-list/kpi-list.component';
import { CircleProgressChartComponent } from './widgets/circle-progress-chart/circle-progress-chart.component';
import { UserRankingComponent } from './widgets/user-ranking/user-ranking.component';
import { FeatureSelectionCardComponent } from './components/feature-selection-card/feature-selection-card.component';
import { CitiesChallengeDashboardComponent } from './components/cities-challenge-dashboard/cities-challenge-dashboard.component';
import { CitiesListComponent } from './widgets/cities-list/cities-list.component';
import { CityInfoCardDialogComponent } from './components/city-info-card-dialog/city-info-card-dialog.component';
import { StageProgressComponent } from './widgets/stage-progress/stage-progress.component';
import { ForecastGraphComponent } from './widgets/forecast-graph/forecast-graph.component';
import { NoCurrentChallengeComponent } from './widgets/no-current-challenge/no-current-challenge/no-current-challenge.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TeamsTableComponent } from './components/team-challenge-v2/teams-table/teams-table.component';
import { AvatarComponent } from './widgets/avatar/avatar.component';
import { ImageCropperComponent } from './widgets/image-cropper/image-cropper.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { TeamChallengeFormComponent } from './components/team-challenge-v2/team-challenge-form/team-challenge-form.component';
// eslint-disable-next-line max-len
import { TeamChallengeConfirmationComponent } from './components/team-challenge-v2/team-challenge-confirmation/team-challenge-confirmation.component';
import { TeamChallengeInfoComponent } from './components/team-challenge-v2/team-challenge-info/team-challenge-info.component';
// eslint-disable-next-line max-len
import { TeamChallengeInfoLocationsComponent } from './components/team-challenge-v2/team-challenge-info-locations/team-challenge-info-locations.component';
import { MatChipsModule } from '@angular/material/chips';
import { TeamChallengeTableComponent } from './components/team-challenge-v2/team-challenge-table/team-challenge-table.component';
// eslint-disable-next-line max-len
import { TeamChallengeStartsSoonCardComponent } from './components/team-challenge-v2/team-challenge-starts-soon-card/team-challenge-starts-soon-card.component';
// eslint-disable-next-line max-len
import { TeamChallengeDashboardComponent } from './components/team-challenge-v2/team-challenge-dashboard/team-challenge-dashboard.component';
import { TeamChallengeOverviewComponent } from './components/team-challenge-v2/team-challenge-overview/team-challenge-overview.component';
import { TeamRankingTableComponent } from './components/team-challenge-v2/team-ranking-table/team-ranking-table.component';
import { GroupRunChallengeDialogComponent } from './components/group-run-challenge-dialog/group-run-challenge-dialog.component';
import { ThemesTabComponent } from './components/themes-tab/themes-tab.component';
import { ThemeDialogComponent } from './components/theme-dialog/theme-dialog.component';
import { CompanyCitiesChallengeDialogComponent } from './components/company-cities-challenge-dialog/company-cities-challenge-dialog/company-cities-challenge-dialog.component';
import { CompanyCitiesChallengeDashboardComponent } from './components/company-cities-challenge-dashboard/company-cities-challenge-dashboard.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CardComponent,
    StepsComponent,
    TeamChallengesComponent,
    UsersListComponent,
    CityLinkDialogComponent,
    CompanyStatusDialogComponent,
    ConfirmationDialogComponent,
    AddRandomTeamsDialogComponent,
    EditCityDialogComponent,
    NotificationMessagesComponent,
    UpdatePropertyDialogComponent,
    AddCityDialogComponent,
    CompanyIsTestDialogComponent,
    CompanyUpdateAvailableLicencesComponent,
    LinkLocationsDialogComponent,
    NewPersonalChallengeDialogComponent,
    NewTeamChallengeDialogComponent,
    PricingTableComponent,
    ImportRouteConfirmationDialogComponent,
    SelectPropertyDialogComponent,
    CompaniesTableComponent,
    SentPushNotificationsTableComponent,
    PushNotificationsFormComponent,
    ManagedCompaniesTableComponent,
    NewEmailFormComponent,
    InfoDialogComponent,
    EditImageAndNameCardComponent,
    CityInfoCardComponent,
    EditCityInfoDialogComponent,
    RoutesTableComponent,
    RouteFormComponent,
    CitiesChallengeTableComponent,
    ChallengeTypeCardComponent,
    ChallengeTypeSelectionComponent,
    CitiesChallengeFormComponent,
    RegionFormComponent,
    CitiesChallengeOverviewComponent,
    AddCitiesChallengeComponent,
    ChallengeCountdownComponent,
    CitiesChallengeStartsSoonCardComponent,
    ChallengeHeaderComponent,
    BadgeCardComponent,
    KpiListComponent,
    CircleProgressChartComponent,
    UserRankingComponent,
    FeatureSelectionCardComponent,
    CitiesChallengeDashboardComponent,
    CitiesListComponent,
    CityInfoCardDialogComponent,
    StageProgressComponent,
    ForecastGraphComponent,
    NoCurrentChallengeComponent,
    TeamsTableComponent,
    AvatarComponent,
    ImageCropperComponent,
    TeamChallengeFormComponent,
    TeamChallengeConfirmationComponent,
    TeamChallengeInfoComponent,
    TeamChallengeInfoLocationsComponent,
    TeamChallengeTableComponent,
    TeamChallengeStartsSoonCardComponent,
    TeamChallengeDashboardComponent,
    TeamChallengeOverviewComponent,
    TeamRankingTableComponent,
    GroupRunChallengeDialogComponent,
    ThemesTabComponent,
    ThemeDialogComponent,
    CompanyCitiesChallengeDialogComponent,
    CompanyCitiesChallengeDashboardComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    HighchartsChartModule,
    TranslateModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatTabsModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatInputModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    PipesModule,
    MatCheckboxModule,
    MaterialFileInputModule,
    MatStepperModule,
    MatExpansionModule,
    MatSlideToggleModule,
    ColorPickerModule,
    MatChipsModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CardComponent,
    StepsComponent,
    TeamChallengesComponent,
    UsersListComponent,
    NotificationMessagesComponent,
    SentPushNotificationsTableComponent,
    PushNotificationsFormComponent,
    CompaniesTableComponent,
    SentPushNotificationsTableComponent,
    ManagedCompaniesTableComponent,
    EditImageAndNameCardComponent,
    CityInfoCardComponent,
    EditCityInfoDialogComponent,
    RoutesTableComponent,
    RouteFormComponent,
    CitiesChallengeTableComponent,
    ChallengeTypeCardComponent,
    ChallengeTypeSelectionComponent,
    CitiesChallengeFormComponent,
    CitiesChallengeOverviewComponent,
    AddCitiesChallengeComponent,
    ChallengeCountdownComponent,
    CitiesChallengeStartsSoonCardComponent,
    ChallengeHeaderComponent,
    BadgeCardComponent,
    KpiListComponent,
    CircleProgressChartComponent,
    UserRankingComponent,
    FeatureSelectionCardComponent,
    CitiesChallengeDashboardComponent,
    CitiesListComponent,
    CityInfoCardDialogComponent,
    StageProgressComponent,
    ForecastGraphComponent,
    NoCurrentChallengeComponent,
    TeamsTableComponent,
    AvatarComponent,
    ImageCropperComponent,
    TeamChallengeFormComponent,
    TeamChallengeConfirmationComponent,
    TeamChallengeInfoComponent,
    TeamChallengeInfoLocationsComponent,
    TeamChallengeTableComponent,
    TeamChallengeStartsSoonCardComponent,
    TeamChallengeDashboardComponent,
    TeamChallengeOverviewComponent,
    TeamRankingTableComponent,
    ThemesTabComponent,
    CompanyCitiesChallengeDashboardComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
