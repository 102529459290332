import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

// Models
import { Company } from '../../models/company.model';
import { CityMarkerPoint } from '../../models/city-marker-point.model';
import { TeamSchema } from '../../models/team-schema.model';
import { CompanyStatus } from '../../models/company-status.enum';
import { PointsSource } from '../../models/points-source.model';
import { PointsSourceBasis } from '../../models/points-source-basis.enum';
import { PointsSourceType } from '../../models/points-source-type.enum';
import { ConfirmationDialogData } from '../../models/confirmation-dialog-data.model';
import { ConfirmationDialogResponse } from '../../models/confirmation-dialog-response.model';
import { ConfirmationDialogDataReturnCodes } from '../../models/confirmation-dialog-data-return-codes.enum';
import { PersonalChallengeSchema } from '../../models/personal-challenge-schema/personal-challenge-schema';

// Services
import { AdminService, CHALLENGES } from '../../services/admin/admin.service';
import { FirebaseService } from '../../services/firebase/firebase.service';
import {
  ReportsService,
  BlobFile,
  ReportStatus,
} from '../../services/reports/reports.service';
import { WhiteLabelService } from '../../services/white-label/white-label.service';

// Helpers
import { Helpers } from '../../helpers/helpers';

// Constants
import { FirebaseConstants } from '../../models/firebase-constants.enum';
import { ONE_PAGER } from '../../constants/constants';

// Dialogs
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { WhiteLabel } from '../../models/white-label.model';
import { AdminTabs } from './admin-tabs.enum';
import { NewPersonalChallengeDialogComponent } from '../../shared/components/new-personal-challenge-dialog/new-personal-challenge-dialog.component';
import { ChallengeSchema } from '../../models/challenge-schema.model';
import { TeamChallenge } from '../../models/team-challenge.model';
import { TeamChallengeSchema } from '../../models/team-challenge-schema/team-challenge-schema';
import { NewTeamChallengeDialogComponent } from '../../shared/components/new-team-challenge-dialog/new-team-challenge-dialog.component';
import { Report } from '../../models/reports/report.model';
import { Options } from 'ngx-qrcode-styling';
import { ReportTypes } from '../../models/reports/report-types.enum';
import { TeamsService } from '../../services/teams/teams.service';
import { Features } from '../../models/features.enum';
import { Notification } from '../../models/notification.model';
import { UserService } from '../../services/user/user.service';
import { Route } from '../../models/cities-challenge/route/route.model';
import { City } from '../../models/cities-challenge/city/city.model';
import { environment } from '../../../environments/environment';
import { GroupRunChallengeDialogComponent } from '../../shared/components/group-run-challenge-dialog/group-run-challenge-dialog.component';
import { GroupRunChallenge } from '../../models/group-run/group-run-challenge/group-run-challenge';
import { Challenge } from '../../models/challenge/challenge.abstract';
import { IWeeklyHistory } from '../../models/team-challenge/weekly-history/weekly-history.model';
import { CompanyCitiesChallenge } from '../../models/company-cities-challenge/company-cities-challenge/company-cities-challenge';
import { IconNames } from '../../models/assets-constants';
// eslint-disable-next-line max-len
import { CompanyCitiesChallengeDialogComponent } from 'src/app/shared/components/company-cities-challenge-dialog/company-cities-challenge-dialog/company-cities-challenge-dialog.component';
import { UserMessageService } from 'src/app/services/user-message/user-message.service';
import { MatPaginator } from '@angular/material/paginator';

interface IReportTypeAndTranslations {
  translation: string;
  type: ReportTypes;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  public tabIndex = 0;

  public reportsForm: UntypedFormGroup;
  public notificationForm: UntypedFormGroup;
  public companyTeamChallenges: Array<TeamChallenge>;
  public companyChallenges: Array<TeamChallenge>;
  public pendingReports = false;
  public qrReady = false;

  readonly TEAM_CHALLENGE_REPORT = 'REPORTS_PAGE.TEAM_CHALLENGE';
  readonly TEAM_ONE_PAGER_REPORT = 'REPORTS_PAGE.TEAM_ONE_PAGER';
  readonly TEAM_CAPTAIN_ONE_PAGER_REPORT =
    'REPORTS_PAGE.TEAM_CAPTAIN_ONE_PAGER';
  readonly COMPANY_ONE_PAGER_REPORT = 'REPORTS_PAGE.COMPANY_ONE_PAGER';
  readonly DEFAULT_REPORT = 'REPORTS_PAGE.DEFAULT';
  readonly REPORT_PENDING = ReportStatus.Pending;
  readonly REPORT_UPLOADING = ReportStatus.Uploading;
  readonly REPORT_SAVED = ReportStatus.Saved;
  readonly REPORT_ERROR = ReportStatus.Error;
  readonly REPORTTYPE_TEAMCHALLENGE = ReportTypes.TeamChallenge;

  public CITIES_CHALLENGE_ICON = IconNames.Earth;

  public companyColumns: Array<string> = [
    'avatar',
    'name',
    'users',
    'availableLicenses',
    'inactiveUsers',
  ];
  public cityColumns: Array<string> = [
    'index',
    'name',
    'lat',
    'lng',
    'flag',
    'edit',
    'delete',
    'info',
  ];

  public teamsColumns: Array<string> = [
    'avatar',
    'name',
    'id',
    'color',
    'edit',
    'delete',
  ];
  public pointsSourcesColumns: Array<string> = [
    'id',
    'description',
    'expectedPerformance',
    'type',
    'basis',
    'pointsAwarded',
    'edit',
    'delete',
  ];
  public whiteLabelsColumns: Array<string> = [
    'logo',
    'name',
    'color',
    'button',
    'edit',
    'delete',
  ];
  public routesColumns: Array<string> = [
    'id',
    'name',
    'totalDistance',
    'edit',
    'delete',
  ];

  public postingReports: Array<IReportTypeAndTranslations> = [
    {
      translation: this.TEAM_CHALLENGE_REPORT,
      type: ReportTypes.TeamChallenge,
    },
    {
      translation: this.TEAM_ONE_PAGER_REPORT,
      type: ReportTypes.TeamOnePager,
    },
    {
      translation: this.TEAM_CAPTAIN_ONE_PAGER_REPORT,
      type: ReportTypes.CaptainOnePager,
    },
    {
      translation: this.COMPANY_ONE_PAGER_REPORT,
      type: ReportTypes.CompanyOnePager,
    },
    // to be implemented in the future
    // {
    //   translation: this.DEFAULT_REPORT,
    //   type: ReportTypes.Default
    // },
  ];

  public companyDataSource = new MatTableDataSource(this.companies);

  public showTestCompanies = environment.production ? false : true;

  public activeCompanies: Array<Company>;
  public inPreparationCompanies: Array<Company>;
  public inactiveCompanies: Array<Company>;
  public pmCompanies: Array<Company>;
  public cityDataSource = new MatTableDataSource(this.cities);
  public teamDataSource = new MatTableDataSource(this.teamSchemas);
  public whiteLabelDataSource = new MatTableDataSource(this.whiteLabels);

  public pointsSourcesDataSource = new MatTableDataSource(this.pointsSources);
  public pointsSourceTypesNames: Array<string> = Object.keys(PointsSourceType)
    .map((key) => PointsSourceType[key])
    .filter((value) => typeof value === 'string') as Array<string>;

  public pointsSourceBasisNames: Array<string> = Object.keys(PointsSourceBasis)
    .map((key) => PointsSourceBasis[key])
    .filter((value) => typeof value === 'string') as Array<string>;

  public companyObs: Observable<string>;
  public selectedId: string;

  public ADMIN_TAB_COMPANIES = '';
  public NOTIFICATION_SENT_SUCCESFULLY = '';
  public NOTIFICATION_ERROR = '';
  public ADMIN_PAGE = '';

  public FILE_ALREADY_EXISTS = '';
  public ERROR_UPLOADING_FILE = '';
  public ERROR_RELEASING_FILE = '';
  public SUCCESS_UPLOAD = '';

  public reportTranslations = null;

  public showSpinnerCompany = true;
  public showSpinnerCity = true;
  public showSpinnerTeams = true;
  public showSpinnerPointsSources = true;
  public showSpinnerRoutes = true;
  public showSpinnerWhiteLabels = true;

  public availableTeamChallengeWeeks = null;

  public companyToReport: Company = null;
  public reportToGenerate: ReportTypes = null;
  public challengeToReport: TeamChallenge;

  public isGeneratingReport = false;
  public isSendingNotification = false;

  public blobFiles: Array<BlobFile> = [];
  public errorMessage = '';
  public successMessage = '';
  public reportSelected = -1;

  public selectedChallenge: string = null;

  public CHALLENGES = CHALLENGES;

  public personalChallengesDataSource = new MatTableDataSource();
  public personalChallengeColumns: Array<string> = [
    'name',
    'fixedDate',
    'startDate',
    'endDate',
    'initialLevel',
    'minLevel',
    'maxLevel',
    'levelDuration',
    'edit',
    'delete',
  ];
  public teamChallengesDataSource = new MatTableDataSource();
  public teamChallengeColumns: Array<string> = [
    'name',
    'teamsAmount',
    'edit',
    'delete',
  ];
  public groupRunChallengesDataSource = new MatTableDataSource();
  public groupRunChallengeColumns: Array<string> = ['name', 'edit', 'delete'];

  public companyCitiesChallengesDataSource = new MatTableDataSource();
  public companyCitiesChallengeColumns: Array<string> = [
    'name',
    'edit',
    'delete',
  ];

  public qrConfig: Options = null;
  @ViewChild('companyCitiesChallengePaginator', { static: false })
  companyCitiesChallengePaginator: MatPaginator;
  @ViewChild('groupRunChallengePaginator', { static: false })
  groupRunChallengePaginator: MatPaginator;

  constructor(
    private adminService: AdminService,
    private userService: UserService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private dateAdapter: DateAdapter<any>,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private reportsService: ReportsService,
    private whiteLabelService: WhiteLabelService,
    private teamsService: TeamsService,
    private userMessageService: UserMessageService
  ) {
    // eslint-disable-next-line max-len
    const regexLink: RegExp = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    this.getTranslations();
    this.dateAdapter.setLocale('de');

    this.reportsForm = this.formBuilder.group({
      company: [null, Validators.required],
      report: [null, Validators.required],
      week: new UntypedFormControl({ value: null, disabled: true }),
      challenge: new UntypedFormControl({ value: null, disabled: true }),
    });

    this.notificationForm = this.formBuilder.group({
      company: [null, Validators.required],
      titleMessageDe: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      titleMessageEn: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      notificationMessageDe: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(200)]),
      ],
      notificationMessageEn: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(200)]),
      ],
      link: ['', Validators.compose([Validators.pattern(regexLink)])],
    });
  }

  get companies(): Array<Company> {
    return this.adminService.companies.sort((companyA, companyB) => {
      const nameA = companyA.name.toLowerCase();
      const nameB = companyB.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  get cities(): Array<City> {
    return this.adminService.cities;
  }
  get teamSchemas(): Array<TeamSchema> {
    return this.adminService.teams;
  }
  get pointsSources(): Array<PointsSource> {
    return this.adminService.pointsSources;
  }
  get routes(): Array<Route> {
    return this.adminService.routes;
  }
  get whiteLabels(): Array<WhiteLabel> {
    return this.adminService.whiteLabels;
  }
  get personalChallenges(): Array<PersonalChallengeSchema> {
    return this.adminService.personalChallenges;
  }
  get teamChallenges(): Array<TeamChallengeSchema> {
    return this.adminService.teamChallenges;
  }
  get groupRunChallenges(): Array<GroupRunChallenge> {
    return this.adminService.groupRunChallenges;
  }
  get companyCitiesChallenges(): Array<CompanyCitiesChallenge> {
    return this.adminService.companyCitiesChallenges;
  }

  ngOnInit(): void {
    this.tabIndex = window.history?.state?.tab
      ? window.history.state.tab
      : AdminTabs.Companies;
    this.adminService
      .getFirebaseData()
      .then(() => {
        this.companyDataSource = new MatTableDataSource(this.companies);
        this.activeCompanies = this.filterCompanies(
          false,
          CompanyStatus.Active
        );
        this.inPreparationCompanies = this.filterCompanies(
          false,
          CompanyStatus.InPreparation
        );
        this.inactiveCompanies = this.filterCompanies(
          false,
          CompanyStatus.Inactive
        );
        this.pmCompanies = this.filterCompanies(true, null);
        this.cityDataSource = new MatTableDataSource(this.cities);
        this.teamDataSource = new MatTableDataSource(this.teamSchemas);
        this.pointsSourcesDataSource = new MatTableDataSource(
          this.pointsSources
        );
        this.whiteLabelDataSource = new MatTableDataSource(this.whiteLabels);
        this.personalChallengesDataSource = new MatTableDataSource(
          this.personalChallenges
        );
        this.teamChallengesDataSource = new MatTableDataSource(
          this.teamChallenges
        );
        this.groupRunChallengesDataSource = new MatTableDataSource(
          this.groupRunChallenges
        );
        this.groupRunChallengesDataSource.paginator = this.groupRunChallengePaginator;
        this.companyCitiesChallengesDataSource = new MatTableDataSource(
          this.companyCitiesChallenges
        );
        this.companyCitiesChallengesDataSource.paginator = this.companyCitiesChallengePaginator;
        this.showSpinnerCompany = false;
        this.showSpinnerCity = false;
        this.showSpinnerTeams = false;
        this.showSpinnerPointsSources = false;
        this.showSpinnerRoutes = false;
        this.showSpinnerWhiteLabels = false;
      })
      .catch((error) => {
        console.log('adminService.getFirebaseData() - error: ', error);
      });
  }

  public goToAddCompany(): void {
    this.router.navigate(['add-company'], { relativeTo: this.route });
  }

  public toggleTestCompanies(): void {
    this.activeCompanies = this.filterCompanies(false, CompanyStatus.Active);
    this.inPreparationCompanies = this.filterCompanies(
      false,
      CompanyStatus.InPreparation
    );
    this.inactiveCompanies = this.filterCompanies(
      false,
      CompanyStatus.Inactive
    );
    this.pmCompanies = this.filterCompanies(true, null);
  }

  public filterCompanies(
    projectManagerCompanies: boolean,
    companyStatus: CompanyStatus
  ): Array<Company> {
    if (projectManagerCompanies) {
      return this.companies.filter(
        (company) =>
          company.features.has(Features.ProjectManager) &&
          (!company.isTestCompany || this.showTestCompanies)
      );
    } else {
      return this.companies.filter(
        (company) =>
          company.status === companyStatus &&
          !company.features.has(Features.ProjectManager) &&
          (!company.isTestCompany || this.showTestCompanies)
      );
    }
  }

  public setSelectedChallenge(selection: string): void {
    this.selectedChallenge = selection;

    if (this.CHALLENGES.COMPANY_CITIES_CHALLENGE === this.selectedChallenge) {
      setTimeout(() => {
        this.companyCitiesChallengesDataSource.paginator = this.companyCitiesChallengePaginator;
      }, 50);
    }

    if (this.CHALLENGES.GROUP_RUN === this.selectedChallenge) {
      setTimeout(() => {
        this.groupRunChallengesDataSource.paginator = this.groupRunChallengePaginator;
      }, 50);
    }
  }

  public openNewChallengeDialog(
    data:
      | ChallengeSchema
      | TeamChallenge
      | GroupRunChallenge
      | CompanyCitiesChallenge
  ): void {
    switch (this.selectedChallenge) {
      case this.CHALLENGES.PERSONAL: {
        const personalChallengeDialogRef = this.dialog.open(
          NewPersonalChallengeDialogComponent,
          {
            data,
            width: '1000px',
          }
        );
        personalChallengeDialogRef.afterClosed().subscribe(() => {
          this.adminService
            .getPersonalChallenges()
            .then((personalChallenges) => {
              this.personalChallengesDataSource = new MatTableDataSource(
                personalChallenges
              );
            })
            .catch((error) => {
              // TODO: add toast
              console.log('error: ', error);
            });
        });
        break;
      }

      case this.CHALLENGES.TEAM: {
        const teamChallengeDialogRef = this.dialog.open(
          NewTeamChallengeDialogComponent,
          {
            data,
            width: '1000px',
          }
        );
        teamChallengeDialogRef.afterClosed().subscribe(() => {
          this.adminService
            .getTeamChallenges()
            .then((teamChallenges) => {
              this.teamChallengesDataSource = new MatTableDataSource(
                teamChallenges
              );
            })
            .catch((error) => {
              // TODO: add toast
              console.log('error: ', error);
            });
        });
        break;
      }

      case this.CHALLENGES.GROUP_RUN: {
        const groupRunChallengeRef = this.dialog.open(
          GroupRunChallengeDialogComponent,
          {
            data,
            width: '1000px',
          }
        );
        groupRunChallengeRef.afterClosed().subscribe(() => {
          this.adminService
            .getGroupRunChallenges()
            .then((groupRunChallenges) => {
              this.groupRunChallengesDataSource = new MatTableDataSource(
                groupRunChallenges
              );
              this.groupRunChallengesDataSource.paginator = this.groupRunChallengePaginator;
            })
            .catch((error) => {
              // TODO: add toast
              console.log('error: ', error);
            });
        });
        break;
      }

      case this.CHALLENGES.COMPANY_CITIES_CHALLENGE: {
        if (!data) {
          this.router.navigate(['add-cities-challenge'], {
            relativeTo: this.route,
          });
        } else {
          const companyCitiesChallengeRef = this.dialog.open(
            CompanyCitiesChallengeDialogComponent,
            {
              data,
              width: '1000px',
            }
          );
          companyCitiesChallengeRef.afterClosed().subscribe(() => {
            this.adminService
              .getCompanyCitiesChallenges()
              .then((companyCitiesChallenges) => {
                this.companyCitiesChallengesDataSource = new MatTableDataSource(
                  companyCitiesChallenges
                );
                this.companyCitiesChallengesDataSource.paginator = this.companyCitiesChallengePaginator;
              })
              .catch((error) => {
                this.userMessageService.snackBarMessage('DEFAULT_ERROR');
                console.log('error: ', error);
              });
          });
        }
        break;
      }
    }
  }

  // TODO: TeamChallenge and ChallengeSchema here should inherit from Challenge. Remove their types when that is done
  public async removeChallenge(
    challenge: ChallengeSchema | TeamChallenge | Challenge
  ): Promise<void> {
    switch (this.selectedChallenge) {
      case CHALLENGES.GROUP_RUN: {
        const confirmationDialog = await this.adminService.deleteChallenge(
          challenge,
          this.selectedChallenge
        );
        if (confirmationDialog) {
          confirmationDialog
            .afterClosed()
            .subscribe((result: ConfirmationDialogResponse) => {
              if (
                result.code ===
                ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly
              ) {
                this.groupRunChallengesDataSource = new MatTableDataSource(
                  this.groupRunChallenges
                );
              }
            });
        }
        break;
      }
      default: {
        const confirmationDialog = this.dialog.open(
          ConfirmationDialogComponent,
          {
            width: '400px',
            data: new ConfirmationDialogData({
              title: this.translateService.instant(
                'REMOVE_CHALLENGE_ALERT.TITLE',
                { name: challenge.name }
              ),
              message: this.translateService.instant(
                'REMOVE_CHALLENGE_ALERT.MESSAGE'
              ),
              action: () =>
                this.adminService.deleteChallenge(
                  challenge,
                  this.selectedChallenge
                ),
            }),
          }
        );

        confirmationDialog
          .afterClosed()
          .subscribe((result: ConfirmationDialogResponse) => {
            switch (result.code) {
              case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
                switch (this.selectedChallenge) {
                  case this.CHALLENGES.PERSONAL:
                    this.personalChallengesDataSource = new MatTableDataSource(
                      this.personalChallenges
                    );
                    break;
                  case this.CHALLENGES.TEAM:
                    this.teamChallengesDataSource = new MatTableDataSource(
                      this.teamChallenges
                    );
                    break;
                }
                this.snackBar.open('Challenge removed successfully', 'Ok', {
                  duration: 4000,
                  panelClass: 'snack-bar-color',
                });
                break;

              case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
                this.snackBar.open('Error removing Challenge', 'Ok', {
                  duration: 4000,
                  panelClass: 'snack-bar-color',
                });
                console.log('error: ', result.error);
                break;
            }
          });
      }
    }
  }
  public goToAddTeam(): void {
    this.router.navigate(['add-team'], { relativeTo: this.route });
  }

  public goToTeam(teamId: string): void {
    this.router.navigate(['edit-team', teamId], {
      relativeTo: this.route,
    });
  }

  public removeTeam(team: TeamSchema): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: new ConfirmationDialogData({
        title: 'Removing Team ' + team.name,
        message: 'Are you sure you want to remove this Team?',
        action: () => this.adminService.deleteTeam(team.id),
      }),
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.teamDataSource = new MatTableDataSource(this.teamSchemas);
          this.snackBar.open('Team removed successfully', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
          this.snackBar.open('Error removing Team', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          console.log('error: ', result.error);
          break;
      }
    });
  }

  public goToAddPointsSource(): void {
    this.router.navigate(['add-points-source'], { relativeTo: this.route });
  }

  public goToPointsSource(pointsSourceId: string): void {
    this.router.navigate(['edit-points-source/' + pointsSourceId], {
      relativeTo: this.route,
    });
  }

  public removePointsSource(pointsSource: PointsSource): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: new ConfirmationDialogData({
        title: 'Removing Point Source ' + pointsSource.id,
        message: 'Are you sure you want to remove this Points Source?',
        action: () => this.adminService.deletePointsSource(pointsSource.id),
      }),
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.pointsSourcesDataSource = new MatTableDataSource(
            this.pointsSources
          );
          this.snackBar.open('Points Source removed successfully', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
          this.snackBar.open('Error removing Points Source', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          console.log('error: ', result.error);
          break;
      }
    });
  }

  public goToAddCity(): void {
    this.router.navigate(['add-city'], { relativeTo: this.route });
  }

  public getFlagPath(countryCode: string): string {
    return Helpers.getFlagPath(countryCode);
  }

  public removeCity(city: CityMarkerPoint): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: new ConfirmationDialogData({
        title: 'Removing City ' + city.name,
        message: 'Are you sure you want to remove this City?',
        action: () => this.adminService.deleteCity(city.id),
      }),
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.cityDataSource = new MatTableDataSource(this.cities);
          this.snackBar.open('City removed successfully', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
          this.snackBar.open('Error removing City', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          console.log('error: ', result.error);
          break;
      }
    });
  }

  public goToAddRoute(): void {
    this.router.navigate(['add-route'], { relativeTo: this.route });
  }

  public goToCompany(companyId: string): void {
    this.router.navigate(['company-info', companyId], {
      relativeTo: this.route,
    });
  }

  public goToUser(userId: string): void {
    this.router.navigate(['user-info', userId], {
      relativeTo: this.route,
    });
  }

  public goToCity(cityId: string): void {
    this.router.navigate(['edit-city', cityId], {
      relativeTo: this.route,
    });
  }

  public goToCityInfo(cityId: string): void {
    this.router.navigate(['city-info', cityId], {
      relativeTo: this.route,
    });
  }

  public goToImportCities(): void {
    this.router.navigate(['import-cities'], { relativeTo: this.route });
  }

  public goToAddWhiteLabel(): void {
    this.router.navigate(['add-white-label'], { relativeTo: this.route });
  }

  public goToWhiteLabel(whiteLabel: WhiteLabel): void {
    const whiteLabelButton = whiteLabel.button ? whiteLabel.button : null;

    this.whiteLabelService.setWhiteLabelToEdit(whiteLabel, whiteLabelButton);

    this.router.navigate(['edit-white-label/' + whiteLabel.id], {
      relativeTo: this.route,
    });
  }

  public removeWhiteLabel(whiteLabel: WhiteLabel): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: new ConfirmationDialogData({
        title: 'Removing White Label ' + whiteLabel.name,
        message: 'Are you sure you want to remove this white label?',
        confirmLabel: 'Delete',
        cancelLabel: 'Cancel',
        action: () => this.adminService.deleteWhiteLabel(whiteLabel.id),
      }),
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.whiteLabelDataSource = new MatTableDataSource(this.whiteLabels);
          this.snackBar.open('White Label removed successfully', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
          this.snackBar.open('Error removing White Label', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          console.log('error: ', result.error);
          break;
      }
    });
  }

  private getTranslations(): void {
    this.translateService
      .stream([
        'ADMIN_TAB_COMPANIES',
        'reports',
        'forms.notification.NOTIFICATION_SENT_SUCCESFULLY',
        'forms.notification.NOTIFICATION_ERROR',
        'FILE_ALREADY_EXISTS',
        'ERROR_UPLOADING_FILE',
        'ERROR_RELEASING_FILE',
        'SUCCESS_UPLOAD',
        'ADMIN_PAGE',
      ])
      .subscribe((values) => {
        this.ADMIN_TAB_COMPANIES = values.ADMIN_TAB_COMPANIES;
        this.reportTranslations = values.reports;
        this.NOTIFICATION_SENT_SUCCESFULLY =
          values['forms.notification.NOTIFICATION_SENT_SUCCESFULLY'];
        this.NOTIFICATION_ERROR =
          values['forms.notification.NOTIFICATION_ERROR'];
        this.FILE_ALREADY_EXISTS = values.FILE_ALREADY_EXISTS;
        this.ERROR_UPLOADING_FILE = values.ERROR_UPLOADING_FILE;
        this.ERROR_RELEASING_FILE = values.ERROR_RELEASING_FILE;
        this.SUCCESS_UPLOAD = values.SUCCESS_UPLOAD;
        this.ADMIN_PAGE = values.ADMIN_PAGE;
      });
  }

  public async selectCompanyToReport(): Promise<void> {
    this.companyToReport = this.reportsForm.get('company').value;
    // Reset all variables
    this.blobFiles = [];
    this.availableTeamChallengeWeeks = null;
    this.companyChallenges = null;

    // Reset form fields
    this.reportsForm.get('report').reset();
    this.reportsForm.get('challenge').reset();
    this.reportsForm.get('week').reset();

    // Get company challenges
    const teamChallengesQuerySnapshot = await this.firebaseService.getCompanyTeamChallenges(
      this.companyToReport.id
    );

    this.companyTeamChallenges = teamChallengesQuerySnapshot.docs.map(
      (teamChallengeSnapshot) => new TeamChallenge(teamChallengeSnapshot.data())
    );
  }

  public selectReportToGenerate(): void {
    // Reset all variables
    this.blobFiles = [];
    this.availableTeamChallengeWeeks = null;
    this.companyChallenges = null;

    // Prepare form fields
    this.reportsForm.get('challenge').enable();
    this.reportsForm.get('challenge').reset();
    this.reportsForm.get('challenge').setValidators(Validators.required);
    this.reportsForm.get('challenge').updateValueAndValidity();
    this.reportsForm.get('week').reset();

    // Get challenges filtered
    const filterChallenges = {
      [ReportTypes.TeamChallenge]: this.companyTeamChallenges,
      [ReportTypes.TeamOnePager]: this.companyTeamChallenges,
      [ReportTypes.CaptainOnePager]: this.companyTeamChallenges,
      [ReportTypes.CompanyOnePager]: [...this.companyTeamChallenges],
    };

    this.reportToGenerate = this.reportsForm.get('report').value;
    this.companyChallenges = filterChallenges[this.reportToGenerate];

    this.companyChallenges.sort(
      (challengeA, challengeB) => challengeB.startDate - challengeA.startDate
    );
  }

  public async selectChallengeToReport(): Promise<void> {
    this.challengeToReport = this.reportsForm.get('challenge').value;
    this.availableTeamChallengeWeeks = null;
    this.blobFiles = [];

    // Reset QR code
    this.qrReady = false;
    this.qrConfig = null;

    if (this.reportToGenerate === ReportTypes.TeamChallenge) {
      this.getTeamChallenge();
    } else {
      this.reportsForm.get('week').disable();
      this.reportsForm.get('week').reset();
      this.reportsForm.get('week').clearValidators();
      this.reportsForm.get('week').updateValueAndValidity();
    }

    if (this.reportToGenerate === ReportTypes.TeamChallenge) {
      const qrData = await this.getOnePagerLink();
      if (!qrData) {
        this.snackBar.open(
          'One Pager not available for this challenge. Please create the one pager first',
          'Ok',
          {
            duration: 4000,
            panelClass: 'snack-bar-color',
          }
        );
        return;
      }

      this.qrReady = true;

      this.qrConfig = {
        width: 300,
        height: 300,
        data: qrData,
        image: '../../assets/img/move-me-new.png',
        margin: 5,
        dotsOptions: {
          color: '#34353d',
          type: 'dots',
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#34353d',
        },
        cornersDotOptions: {
          type: 'dot',
          color: '#34353d',
        },
        backgroundOptions: {
          color: '#ffffff',
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 0,
        },
      };
    }
  }

  private getOnePagerLink(): Promise<string> {
    return this.firebaseService
      .getOnePagerReport(this.companyToReport.id, this.challengeToReport.id)
      .then((reportQuery) => {
        if (!reportQuery.empty) {
          const report = new Report(reportQuery.docs[0].data());
          return report.shortLink;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.log('error getting one pager report: ', error);
        return null;
      });
  }

  public selectWeekToReport(): void {
    this.blobFiles = [];
  }

  public async getTeamChallenge(): Promise<void> {
    this.availableTeamChallengeWeeks = await this.teamsService.getTeamChallengeWeeks(
      this.companyToReport.id,
      this.challengeToReport.id
    );
    this.availableTeamChallengeWeeks.sort(
      (weekA: IWeeklyHistory, weekB: IWeeklyHistory) => {
        if (
          weekA.id.toString().includes('Week') &&
          weekB.id.toString().includes('Week')
        ) {
          const weekANumber = parseInt(weekA.id.toString().split(' ')[1], 10);
          const weekBNumber = parseInt(weekB.id.toString().split(' ')[1], 10);
          return weekANumber - weekBNumber;
        } else {
          if (weekA.id.toString().includes('Week')) {
            return 1;
          } else {
            return -1;
          }
        }
      }
    );
    this.reportsForm.get('week').enable();
    this.reportsForm.get('week').setValidators(Validators.required);
    this.reportsForm.get('week').updateValueAndValidity();
  }

  public generateReport(): void {
    this.setIsGeneratingReport(true);
    this.clearMessages();

    const generate = {
      [ReportTypes.TeamChallenge]: this.generateTeamReport.bind(this),
      [ReportTypes.TeamOnePager]: this.generateOnePagerReport.bind(this),
      [ReportTypes.CaptainOnePager]: this.generateOnePagerReport.bind(this),
      [ReportTypes.CompanyOnePager]: this.generateCompanyOnePagerReport.bind(
        this
      ),
    };

    generate[this.reportToGenerate]();
  }

  public generateTeamReport(): void {
    const challenge = new TeamChallenge(this.challengeToReport);
    const weekRanking = this.reportsForm.get('week').value;
    this.reportsService
      .generateTeamChallengeReport(this.companyToReport, challenge, weekRanking)
      .then((downloadUrl: string) => {
        window.open(downloadUrl, '_blank');
        this.setIsGeneratingReport(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        this.setIsGeneratingReport(false);
      });
  }

  public generateOnePagerReport(): void {
    const documentTitle =
      this.reportToGenerate === ReportTypes.TeamOnePager
        ? this.challengeToReport.name.replace(' ', '-') + '_Team.pdf'
        : this.challengeToReport.name.replace(' ', '-') + '_Captain.pdf';
    const template =
      this.reportToGenerate === ReportTypes.TeamOnePager
        ? ONE_PAGER.TEAM_TEMPLATE_PATH
        : ONE_PAGER.CAPTAIN_TEMPLATE_PATH;

    this.reportsService
      .generateTeamOnePagerReport(
        this.companyToReport,
        documentTitle,
        this.challengeToReport,
        this.reportTranslations,
        template
      )
      .then((blobFiles: Array<BlobFile>) => {
        this.blobFiles = blobFiles;
        this.pendingReports = this.blobFiles.some(
          (blobFile) => blobFile.status === ReportStatus.Pending
        );
        this.setIsGeneratingReport(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        this.setIsGeneratingReport(false);
      });
  }

  public generateCompanyOnePagerReport(): void {
    const documentTitle =
      this.companyToReport.id +
      '_' +
      this.challengeToReport.name.replace(' ', '-') +
      '.pdf';
    this.reportsService
      .generateCompanyOnePagerReport(
        this.companyToReport,
        documentTitle,
        this.challengeToReport,
        this.reportTranslations
      )
      .then((blobFiles: Array<BlobFile>) => {
        this.blobFiles = blobFiles;
        this.pendingReports = this.blobFiles.some(
          (blobFile) => blobFile.status === ReportStatus.Pending
        );
        this.setIsGeneratingReport(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        this.setIsGeneratingReport(false);
      });
  }

  public openBlob(blob: Blob): void {
    this.reportsService.openBlob(blob);
  }

  private setIsGeneratingReport(value: boolean): void {
    this.isGeneratingReport = value;
  }

  async getDataUri(url): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      const image = new Image();

      image.onload = (): void => {
        resolve(image);
      };

      image.src = url;
    });
  }

  public async releaseReport(report: Report): Promise<void> {
    const reportId = report.name.replace('.pdf', '');
    this.reportsService
      .releaseReport(report, reportId, this.companyToReport.id)
      .catch((error) => {
        console.log('addNewReport - error: ', error);
        this.errorMessage = this.ERROR_RELEASING_FILE;
      });
  }

  public previewFile(fileInputEvent: Event): void {
    const eventTarget = fileInputEvent.target as HTMLInputElement;
    const fileToUpload = eventTarget.files[0];
    this.blobFiles = [
      {
        title: fileToUpload.name,
        blob: fileToUpload,
        status: ReportStatus.Pending,
      },
    ];
    this.clearMessages();
  }

  public uploadPdfFile(blobFile: BlobFile): void {
    blobFile.status = ReportStatus.Uploading;
    this.pendingReports = this.blobFiles.some(
      (file) => file.status === ReportStatus.Pending
    );

    this.uploadReport(blobFile, this.reportToGenerate)
      .then((report) => {
        this.releaseReport(report)
          .then(() => {
            blobFile.status = ReportStatus.Saved;
            this.successMessage = this.SUCCESS_UPLOAD;
          })
          .catch((error) => {
            blobFile.status = ReportStatus.Error;
            this.errorMessage = this.ERROR_RELEASING_FILE;
            console.log(error);
          });
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  }

  public discardFile(blobFile: BlobFile): void {
    const index = this.blobFiles.findIndex(
      (blobFileElement) => blobFileElement.title === blobFile.title
    );
    if (index > -1) {
      this.blobFiles.splice(index, 1);
    }
    this.pendingReports = this.blobFiles.some(
      (file) => file.status === ReportStatus.Pending
    );
  }

  public clearMessages(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }

  private async uploadReport(
    blobFile: BlobFile,
    reportType: ReportTypes
  ): Promise<Report> {
    const fileToSave = new File([blobFile.blob], blobFile.title);
    const documentTitle = fileToSave.name;
    const path =
      FirebaseConstants.CompaniesFolder +
      this.companyToReport.id +
      FirebaseConstants.ReportsFolder +
      documentTitle;

    if (!(await this.reportsService.reportExist(path))) {
      try {
        const report = await this.reportsService.uploadReport(
          fileToSave,
          documentTitle,
          reportType,
          this.companyToReport.id,
          this.challengeToReport.id
        );
        return report;
      } catch (error) {
        console.log('Error: ', error);
        this.errorMessage = this.ERROR_UPLOADING_FILE;
        this.showMessage(this.errorMessage);
      }
    } else {
      this.errorMessage = this.FILE_ALREADY_EXISTS;
      this.showMessage(this.errorMessage);
    }
    blobFile.status = ReportStatus.Error;
    return Promise.reject(this.errorMessage);
  }

  public uploadPendingReports(): void {
    const blobFilesToUpload = this.blobFiles.filter(
      (blobFile) => blobFile.status === ReportStatus.Pending
    );
    blobFilesToUpload.forEach((blobFileToUpload) =>
      this.uploadPdfFile(blobFileToUpload)
    );
  }

  public discardPendingReports(): void {
    const blobFilesToDiscard = this.blobFiles.filter(
      (blobFile) => blobFile.status === ReportStatus.Pending
    );
    blobFilesToDiscard.forEach((blobFileToUpload) =>
      this.discardFile(blobFileToUpload)
    );
  }

  public showMessage(message: string): void {
    this.snackBar.open(message, 'Ok', {
      duration: 7000,
      panelClass: 'snack-bar-color',
    });
  }

  public sendNotification(): void {
    this.isSendingNotification = true;

    const notification = new Notification({
      id: '',
      read: false,
      title: {
        de: this.notificationForm.value.titleMessageDe,
        en: this.notificationForm.value.titleMessageEn,
      },
      message: {
        de: this.notificationForm.value.notificationMessageDe,
        en: this.notificationForm.value.notificationMessageEn,
      },
      link: this.notificationForm.value.link
        ? this.notificationForm.value.link
        : null,
      recipientCompanyIds: [this.notificationForm.value.company.id],
    });

    this.firebaseService
      .sendAdminNotification(notification, this.userService.user.companyId)
      .then(() => {
        this.snackBar.open(this.NOTIFICATION_SENT_SUCCESFULLY, 'Ok', {
          duration: 5000,
          panelClass: 'snack-bar-color',
        });
      })
      .catch(() => {
        this.snackBar.open(this.NOTIFICATION_ERROR, 'Ok', {
          duration: 5000,
          panelClass: 'snack-bar-color',
        });
      })
      .finally(() => {
        this.notificationForm.get('titleMessageDe').reset();
        this.notificationForm.get('titleMessageEn').reset();
        this.notificationForm.get('notificationMessageDe').reset();
        this.notificationForm.get('notificationMessageEn').reset();
        this.notificationForm.get('link').reset();
        this.isSendingNotification = false;
      });
  }

  public applyFilterToGroupRunChallenges(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.groupRunChallengesDataSource.filter = filterValue.trim().toLowerCase();

    this.groupRunChallengesDataSource.paginator = this.groupRunChallengePaginator;
    if (this.groupRunChallengesDataSource.paginator) {
      this.groupRunChallengesDataSource.paginator.firstPage();
    }
  }

  public applyFilterToCompanyCitiesChallenges(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.companyCitiesChallengesDataSource.filter = filterValue
      .trim()
      .toLowerCase();

    this.companyCitiesChallengesDataSource.paginator = this.companyCitiesChallengePaginator;
    if (this.companyCitiesChallengesDataSource.paginator) {
      this.companyCitiesChallengesDataSource.paginator.firstPage();
    }
  }
}
