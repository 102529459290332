<mat-card class="kpi-list-container">
  <mat-card-header>
    <mat-card-title>
      {{ kpiList.title | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="kpi-items-container" #kpiCardContent>
    <div *ngFor="let kpi of kpiList.list" class="kpi-item">
      <div class="icon" *ngIf="kpi.icon">
        <img [src]="kpi.icon" alt="Kpi icon">
      </div>
      <p class="description"> {{ kpi.name | translate }} </p>
      <p> {{ kpi.value | number: '1.0-0':'de' }} </p>
    </div>
  </mat-card-content>
</mat-card>
