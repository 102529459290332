import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StatisticsBasis } from '../../constants/constants';
import { Helpers } from '../../helpers/helpers';
import { AssetsConstants } from '../../models/assets-constants';
import { CitiesChallenge } from '../../models/cities-challenge/cities-challenge/cities-challenge.model';
import { CitiesChallengeDayPerformance } from '../../models/cities-challenge/cities-day-challenge-performance/cities-challenge-day-performance.model';
import { CompanyCitiesChallenge } from '../../models/company-cities-challenge/company-cities-challenge/company-cities-challenge';
import { CompanyCitiesChallengeService } from '../../services/company-cities-challenge/company-cities-challenge.service';
import { StatisticsService } from '../../services/statistics/statistics.service';
import { INoCurrentChallenge } from '../../shared/widgets/no-current-challenge/no-current-challenge/no-current-challenge.component';
import { ICompanyCitiesChallengeDashboard } from '../../shared/components/company-cities-challenge-dashboard/company-cities-challenge-dashboard.component';
import { CompanyService } from '../../services/company/company.service';
import { Company } from '../../models/company.model';

@Component({
  selector: 'app-company-cities-challenge-container',
  templateUrl: './company-cities-challenge-container.component.html',
  styleUrls: ['./company-cities-challenge-container.component.scss'],
})
export class CompanyCitiesChallengeContainerComponent implements OnInit {
  @Input() companyId: string | null;

  public loading = true;
  public companyCitiesChallenges: Array<CompanyCitiesChallenge>;
  public currentChallengeDashboard: ICompanyCitiesChallengeDashboard | null;
  public completedChallengeDashboard: ICompanyCitiesChallengeDashboard | null;
  public nextCompanyCitiesChallenge: CompanyCitiesChallenge;
  public completedChallenges: Array<CompanyCitiesChallenge> = [];
  public noCitiesChallenge: INoCurrentChallenge = {
    image: AssetsConstants.GroupOfPeopleAndLogo,
    title: 'CITIES_CHALLENGE.NO_CURRENT_CHALLENGE.TITLE',
  };

  public selectedChallengeFormControl: FormControl = new FormControl();
  public selectedChallenge: CitiesChallenge;
  public company: Company;

  constructor(
    private route: ActivatedRoute,
    private companyCitiesChallengeService: CompanyCitiesChallengeService,
    private statisticsService: StatisticsService,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.selectedChallengeFormControl.valueChanges.subscribe((challenge) =>
      this.setCompletedCitiesChallengeDashboardData(challenge)
    );

    if (!this.companyId) {
      this.companyId = this.route.snapshot.paramMap.get('companyId');
    }

    if (this.companyId) {
      const companyPromise = this.companyService.getCompany(this.companyId);
      const companyCitiesChallengesPromise = this.companyCitiesChallengeService.getCompanyCitiesChallengesById(
        this.companyId
      );

      Promise.all([companyPromise, companyCitiesChallengesPromise]).then(
        ([company, companyCitiesChallenges]) => {
          const today = Helpers.createISODate();
          this.company = company;
          this.companyCitiesChallenges = companyCitiesChallenges;
          this.completedChallenges = companyCitiesChallenges.filter(
            (challenge) => challenge.endDate < today
          );
          if (
            companyCitiesChallenges.length > 0 &&
            companyCitiesChallenges.length !== this.completedChallenges.length
          ) {
            const nextOrOnGoingChallenges = companyCitiesChallenges.slice(
              0,
              companyCitiesChallenges.length - this.completedChallenges.length
            );

            const onGoingChallenge = nextOrOnGoingChallenges.find((challenge) =>
              challenge.isInProgress()
            );
            if (onGoingChallenge) {
              this.getCitiesChallengeDashboardData(
                onGoingChallenge,
                this.company
              ).then((currentCitiesChallengeDashboard) => {
                this.currentChallengeDashboard = currentCitiesChallengeDashboard;
                this.loading = false;
              });
            } else {
              this.nextCompanyCitiesChallenge = nextOrOnGoingChallenges
                .filter((challenge) => challenge.startDate > today)
                .reverse()[0];
              this.loading = false;
            }
          }

          if (this.completedChallenges.length === 0) {
            this.selectedChallengeFormControl.disable({ emitEvent: false });
            this.loading = false;
          }
        }
      );
    }
  }

  private setCompletedCitiesChallengeDashboardData(
    challenge: CompanyCitiesChallenge
  ): void {
    this.completedChallengeDashboard = null;
    this.loading = true;
    this.getCitiesChallengeDashboardData(challenge, this.company)
      .then(
        (citiesChallengeDashboard) =>
          (this.completedChallengeDashboard = citiesChallengeDashboard)
      )
      .finally(() => (this.loading = false));
  }

  public getCitiesChallengeDashboardData(
    companyCitiesChallenge: CompanyCitiesChallenge,
    company: Company
  ): Promise<ICompanyCitiesChallengeDashboard> {
    const statisticsPromise = this.statisticsService.getTrainingsStatistics(
      company.id,
      StatisticsBasis.Challenges,
      companyCitiesChallenge.id
    );
    const citiesChallengeDailyPerformancePromise = this.companyCitiesChallengeService.getCompanyCitiesChallengeDailyPerformances(
      companyCitiesChallenge.id
    );
    const participantsPromise = this.companyCitiesChallengeService.getCompanyCitiesChallengeParticipants(
      companyCitiesChallenge.id
    );

    return Promise.all([
      statisticsPromise,
      citiesChallengeDailyPerformancePromise,
      participantsPromise,
    ])
      .then(
        ([
          companyTrainingData,
          citiesChallengeDayPerformances,
          participants,
        ]) => {
          citiesChallengeDayPerformances.push(
            new CitiesChallengeDayPerformance({
              date: Helpers.formatDateYYYYMMDD(new Date()),
              ...companyCitiesChallenge.totalPerformance.toObject(),
            })
          );
          return {
            citiesChallenge: companyCitiesChallenge,
            companyTrainingData,
            citiesChallengeDays: citiesChallengeDayPerformances,
            participants,
            company,
          };
        }
      )
      .catch((error) => {
        console.log(
          'Error getting cities challenge and training data: ',
          error
        );
        return {
          citiesChallenge: null,
          companyTrainingData: [],
          citiesChallengeDays: [],
          participants: [],
          company: null,
        };
      });
  }
}
