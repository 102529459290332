import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';

export type SnackBarOptions = 'ERROR' | 'SUCCESS' | 'STANDARD';

export interface ISnackBarStyle {
  panelClass: string | Array<string>;
}

@Injectable({
  providedIn: 'root',
})
export class UserMessageService {
  public SNACK_BAR_STYLES: { [T in SnackBarOptions]: ISnackBarStyle } = {
    ERROR: {
      panelClass: ['snack-bar-error'],
    },
    SUCCESS: {
      panelClass: ['snack-bar-success'],
    },
    STANDARD: {
      panelClass: ['snack-bar-standard'],
    },
  };

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {}

  public snackBarMessage(
    message: string,
    style: SnackBarOptions = 'STANDARD'
  ): void {
    this.snackBar.open(
      this.translateService.instant(message),
      this.translateService.instant('USER_MESSAGE_SERVICE.OK'),
      {
        panelClass: this.SNACK_BAR_STYLES[style].panelClass,
      }
    );
  }

  public openConfirmationDialog(
    data: ConfirmationDialogData
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): MatDialogRef<ConfirmationDialogComponent, any> {
    return this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data,
    });
  }
}
