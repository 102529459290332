// New features must be added in alphabetical order.
export enum Features {
  BicycleConverter = 'BicycleConverter',
  Events = 'Events',
  JoinTeamCard = 'JoinTeamCard',
  LimitedLicenses = 'LimitedLicenses',
  Locations = 'Locations',
  Marketplace = 'Marketplace',
  NotSetAutomaticTeam = 'NotSetAutomaticTeam',
  NotShowWhiteLabelLogoOnReport = 'NotShowWhiteLabelLogoOnReport',
  OrganisationBoard = 'OrganisationBoard',
  ProjectManager = 'ProjectManager',
  ReferralProgramDisabled = 'ReferralProgramDisabled',
  Regions = 'Regions',
  SetAutomaticTeam = 'SetAutomaticTeam',
  ShareEmail = 'ShareEmail',
  TeamChallenge = 'TeamChallenge',
  UnitConverter = 'UnitConverter',
  TrainingConverter = 'TrainingConverter',
  SwimConverter = 'SwimConverter',
  WhiteLabel = 'WhiteLabel',
}
