<div class="form-container">
  <div *ngIf="possibleRecipients.length === 0" class="no-recipients-available">
    {{ 'forms.notification.NO_RECIPIENTS_AVAILABLE' | translate }}
  </div>
  <form [formGroup]="notificationForm" class="notification-form" (ngSubmit)="sendNotification()">
    <div class="select-container">
      <mat-form-field>
        <mat-label>{{ 'forms.notification.RECIPIENTS' | translate }}</mat-label>
        <mat-select multiple formControlName="recipientGroup">
          <mat-option *ngFor="let recipient of possibleRecipients" [value]="recipient.id">{{ recipient.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="sendNotificationType !== SEND_NOTIFICATION_TYPE.ProjectManager && sendNotificationType !== SEND_NOTIFICATION_TYPE.MoveMeAdmin && possibleRecipients.length > 0" class="company-image-container">
      <img class="company-image" #companyImage [src]="possibleRecipients[0].avatar">
    </div>
    <div class="checkbox-container">
      <mat-checkbox color="primary" formControlName="deLanguage" (change)="switchValidator('de')">
        {{'languages.de' | translate}}
      </mat-checkbox>
      <mat-checkbox color="primary" formControlName="enLanguage" (change)="switchValidator('en')">
        {{'languages.en' | translate}}
      </mat-checkbox>
    </div>
    <div class="notification-area">
      <div>
        <mat-form-field appearance="fill" *ngIf="notificationForm.get('deLanguage').value">
          <mat-label>{{ 'forms.notification.NOTIFICATION_TITLE_LABEL_DE' | translate }}</mat-label>
          <input matInput #titleMessageDe formControlName="titleMessageDe">
          <mat-hint align="end">{{titleMessageDe.value.length}} / {{TITLE_CHARACTERS_LIMIT}}</mat-hint>
          <mat-error *ngIf="notificationForm.get('titleMessageDe').hasError('required')">
            {{ 'forms.notification.REQUIRED_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" [ngStyle]="{display: notificationForm.get('enLanguage').value ? '' : 'none'}">
          <mat-label>{{ 'forms.notification.NOTIFICATION_TITLE_LABEL_EN' | translate }}</mat-label>
          <input matInput #titleMessageEn formControlName="titleMessageEn">
          <mat-hint align="end">{{titleMessageEn.value.length}} / {{TITLE_CHARACTERS_LIMIT}}</mat-hint>
          <mat-error *ngIf="notificationForm.get('titleMessageEn').hasError('required')">
            {{ 'forms.notification.REQUIRED_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" *ngIf="notificationForm.get('deLanguage').value">
          <mat-label>{{ 'forms.notification.NOTIFICATION_MESSAGE_LABEL_DE' | translate }}</mat-label>
          <textarea matInput
            #notificationMessageDe
            cdkTextareaAutosize
            cdkAutosizeMinRows="4"
            cdkAutosizeMaxRows="5"
            formControlName="notificationMessageDe"></textarea>
          <mat-hint align="end">{{notificationMessageDe.value.length}} / {{BODY_CHARACTERS_LIMIT}}</mat-hint>
          <mat-error *ngIf="notificationForm.get('notificationMessageDe').hasError('required')">
            {{ 'forms.notification.REQUIRED_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" [ngStyle]="{display: notificationForm.get('enLanguage').value ? '' : 'none'}">
          <mat-label>{{ 'forms.notification.NOTIFICATION_MESSAGE_LABEL_EN' | translate }}</mat-label>
          <textarea matInput
            #notificationMessageEn
            cdkTextareaAutosize
            cdkAutosizeMinRows="4"
            cdkAutosizeMaxRows="5"
            formControlName="notificationMessageEn"></textarea>
          <mat-hint align="end">{{notificationMessageEn.value.length}} / {{BODY_CHARACTERS_LIMIT}}</mat-hint>
          <mat-error *ngIf="notificationForm.get('notificationMessageEn').hasError('required')">
            {{ 'forms.notification.REQUIRED_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" *ngIf="notificationForm.get('enLanguage').value || notificationForm.get('deLanguage').value">
          <mat-label>{{ 'forms.notification.LINK_LABEL' | translate }}</mat-label>
          <input matInput formControlName="link">
          <mat-error *ngIf="notificationForm.get('link').hasError('pattern')">
            {{ 'forms.notification.PATTERN_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="buttons">
      <button mat-raised-button type="submit" [disabled]="!notificationForm.valid || isSendingNotification || (!notificationForm.get('enLanguage').value && !notificationForm.get('deLanguage').value)">
        {{ 'forms.notification.SEND_NOTIFICATION' | translate }}
        <mat-spinner [diameter]="16" *ngIf="isSendingNotification"></mat-spinner>
      </button>
    </div>
  </form>
</div>
