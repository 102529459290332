import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SeriesOptionsType } from 'highcharts';
import { IconNames } from 'src/app/models/assets-constants';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { de, enUS } from 'date-fns/locale';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

// Models
import { CitiesChallenge } from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';
import { CompanyTrainingData } from 'src/app/models/company-training-data/company-training-data.model';
import { CitiesChallengeDayPerformance } from 'src/app/models/cities-challenge/cities-day-challenge-performance/cities-challenge-day-performance.model';
import { City } from 'src/app/models/cities-challenge/city/city.model';

// Components
import { ICitiesList } from '../../widgets/cities-list/cities-list.component';
import { IUserRanking } from '../../widgets/user-ranking/user-ranking.component';
import { ICircleProgressCard } from '../../widgets/circle-progress-chart/circle-progress-chart.component';
import { IKpiList } from '../../widgets/kpi-list/kpi-list.component';
import { IStageProgressCard } from '../../widgets/stage-progress/stage-progress.component';
import { IForecastGraph } from '../../widgets/forecast-graph/forecast-graph.component';

// Helpers
import { Helpers } from 'src/app/helpers/helpers';

// Services
import { TranslateService } from '@ngx-translate/core';
import { UserMessageService } from 'src/app/services/user-message/user-message.service';
import { ReportsService } from 'src/app/services/reports/reports.service';

const DATEPICKER_FORMAT = {
  parse: {
    dateInput: 'dd.MM.yyyy',
  },
  display: {
    dateInput: 'dd.MM.yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'dd.MM.yyyy',
    monthYearA11yLabel: 'MMM yyyy',
  },
};

export interface ICitiesChallengeDashboard {
  citiesChallenge: CitiesChallenge | null;
  companyTrainingData: Array<CompanyTrainingData>;
  citiesChallengeDays: Array<CitiesChallengeDayPerformance>;
  companyId: string | null;
}

@Component({
  selector: 'app-cities-challenge-dashboard',
  templateUrl: './cities-challenge-dashboard.component.html',
  styleUrls: ['./cities-challenge-dashboard.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT },
  ],
})
export class CitiesChallengeDashboardComponent implements OnInit {
  @Input() citiesChallengeDashboard: ICitiesChallengeDashboard;

  public duration = 0;
  public CITIES_CHALLENGE_ICON = IconNames.Earth;
  public dateFormControl: FormControl = new FormControl();
  public showSpinner = false;
  public downloading = false;
  public maxDate: string;
  public today = Helpers.createISODateTime();

  public challengeDayPerformance: CitiesChallengeDayPerformance;
  public citiesList: ICitiesList;
  public userRanking: IUserRanking;
  public circleProgressCard: ICircleProgressCard;
  public stageProgressCard: IStageProgressCard;
  public challengeKpiList: IKpiList;
  public performanceKpiList: IKpiList;
  public forecastGraphCard: IForecastGraph;

  public forecastGraphReady = false;

  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private userMessageService: UserMessageService,
    private reportsService: ReportsService
  ) {}

  ngOnInit(): void {
    this.setLocaleToDateAdapter();
    this.duration = this.citiesChallengeDashboard.citiesChallenge.getDaysDuration();
    this.challengeDayPerformance = new CitiesChallengeDayPerformance({
      date:
        this.today < this.citiesChallengeDashboard.citiesChallenge.endDate
          ? this.today
          : this.citiesChallengeDashboard.citiesChallenge.endDate,
      ...this.citiesChallengeDashboard.citiesChallenge.totalPerformance.toObject(),
    });
    this.maxDate =
      this.today <= this.citiesChallengeDashboard.citiesChallenge.endDate
        ? this.today
        : this.citiesChallengeDashboard.citiesChallenge.endDate;
    this.dateFormControl.disable();
    this.dateFormControl.setValue(this.maxDate);
    this.setUserRankingData();
    this.setForecastGraphData();
    this.calculatePerformanceCards();

    this.translateService.onLangChange.subscribe(() => {
      this.setForecastGraphData();
      this.setLocaleToDateAdapter();
    });
  }

  private calculatePerformanceCards(): void {
    this.setCitiesListData();
    this.setCircleProgressCardData();
    this.setStageProgressData();
    this.setKpiLists();
  }

  private setCitiesListData(): void {
    this.citiesList = {
      title: 'CITIES_CHALLENGE.CITIES_LIST.TITLE',
      citiesChallenge: this.citiesChallengeDashboard.citiesChallenge,
      cityChallengePerformance: this.challengeDayPerformance,
    };
  }

  private setCircleProgressCardData(): void {
    this.circleProgressCard = {
      title: 'CIRCLE_PROGRESS_CARD.CITIES_CHALLENGE.TITLE',
      totalProgress: this.challengeDayPerformance.totalProgress,
      progressTitle: `${this.challengeDayPerformance.totalProgress.toFixed(
        2
      )}% / 100%`,
    };
  }

  private setStageProgressData(): void {
    const currentRoute = this.citiesChallengeDashboard.citiesChallenge.regions[
      this.challengeDayPerformance.regionIndex
    ].route;

    if (
      currentRoute.cityMarkerPoints[this.challengeDayPerformance.stageIndex + 1]
    ) {
      this.stageProgressCard = {
        title: 'STAGE_PROGRESS_CARD.CITIES_CHALLENGE.TITLE',
        totalProgress: this.challengeDayPerformance.stageProgress,
        cityFrom:
          currentRoute.cityMarkerPoints[this.challengeDayPerformance.stageIndex]
            .city,
        cityTo:
          currentRoute.cityMarkerPoints[
            this.challengeDayPerformance.stageIndex + 1
          ].city,
        progressTitle: `${this.challengeDayPerformance.stageProgress.toFixed(
          2
        )}% / 100%`,
        progressSubtitle:
          'STAGE_PROGRESS_CARD.CITIES_CHALLENGE.PROGRESS_SUBTITLE',
      };
    } else {
      this.stageProgressCard = {
        title: 'STAGE_PROGRESS_CARD.CITIES_CHALLENGE.TITLE',
        totalProgress: 0,
        cityFrom: new City({
          name: '',
          countryAlphaCode: 'default',
        }),
        cityTo: new City({
          name: '',
          countryAlphaCode: 'default',
        }),
        progressSubtitle: 'STAGE_PROGRESS_CARD.CITIES_CHALLENGE.ERROR_MESSAGE',
      };
    }
  }

  private setUserRankingData(): void {
    this.userRanking = {
      title: 'USER_RANKING.CITIES_CHALLENGE.TITLE',
      emptyRankingMessage:
        'USER_RANKING.CITIES_CHALLENGE.EMPTY_RANKING_MESSAGE',
      badgeIcons: [
        IconNames.FirstPlaceCitiesChallenge,
        IconNames.SecondPlaceCitiesChallenge,
        IconNames.ThirdPlaceCitiesChallenge,
      ],
      ranking: this.citiesChallengeDashboard.companyTrainingData.map(
        (userStatistics) => ({
          avatar: userStatistics.avatar,
          nickname: userStatistics.nickname,
          performance: userStatistics.steps,
        })
      ),
    };
  }

  private setKpiLists(): void {
    this.challengeKpiList = {
      title: 'KPI_LIST.CITIES_CHALLENGE.TITLE_CHALLENGE',
      list: [
        {
          name: 'KPI_LIST.CITIES_CHALLENGE.PARTICIPANTS',
          value: this.challengeDayPerformance.users,
        },
        {
          name: 'KPI_LIST.CITIES_CHALLENGE.CHALLENGE_DAY',
          value: this.challengeDayPerformance.day,
        },
        {
          name: 'KPI_LIST.CITIES_CHALLENGE.EXPECTED_PERFORMANCE',
          value: this.citiesChallengeDashboard.citiesChallenge
            .expectedDailySteps,
        },
      ],
    };

    this.performanceKpiList = {
      title: 'KPI_LIST.CITIES_CHALLENGE.TITLE_PERFORMANCE',
      list: [
        {
          name: 'KPI_LIST.CITIES_CHALLENGE.EXPECTED',
          value: this.challengeDayPerformance.expectedTotalProgress,
        },
        {
          name: 'KPI_LIST.CITIES_CHALLENGE.REAL',
          value: this.challengeDayPerformance.totalProgress,
        },
        {
          name: 'KPI_LIST.CITIES_CHALLENGE.AVERAGE',
          value: this.challengeDayPerformance.average,
        },
      ],
    };
  }

  private setForecastGraphData(): void {
    const categories = this.getForecastGraphCategories();
    const series = this.getForecastGraphSeries(categories);

    this.forecastGraphCard = {
      title: 'FORECAST_GRAPH.CITIES_CHALLENGE.TITLE',
      chartOptions: {
        title: {
          text: '',
        },
        colors: ['#F97A1C', '#8b8c95', '#F97A1C'],
        xAxis: {
          categories,
        },
        series,
      },
    };

    this.forecastGraphReady = true;
  }

  private getForecastGraphCategories(): Array<string> {
    return Helpers.generateDaysArray(
      this.citiesChallengeDashboard.citiesChallenge.startDate,
      this.citiesChallengeDashboard.citiesChallenge.endDate
    );
  }

  private getForecastGraphSeries(
    days: Array<string>
  ): Array<SeriesOptionsType> {
    if (days.length > 0) {
      const expectedProgressByDay = 100 / days.length;
      const expectedPerformance = this.citiesChallengeDashboard.citiesChallenge
        .expectedDailySteps;
      const forecastData = Array(days.length - 1).fill(
        null,
        0,
        this.citiesChallengeDashboard.citiesChallengeDays.length
      );
      let currentAveragePerformance = 0;

      if (this.citiesChallengeDashboard.citiesChallengeDays.length > 0) {
        currentAveragePerformance = this.citiesChallengeDashboard
          .citiesChallengeDays[
          this.citiesChallengeDashboard.citiesChallengeDays.length - 1
        ].average;
        forecastData[
          this.citiesChallengeDashboard.citiesChallengeDays.length - 1
        ] = this.citiesChallengeDashboard.citiesChallengeDays[
          this.citiesChallengeDashboard.citiesChallengeDays.length - 1
        ].totalProgress;
      }

      for (
        let i = this.citiesChallengeDashboard.citiesChallengeDays.length;
        i < days.length;
        i++
      ) {
        forecastData[i] =
          ((currentAveragePerformance * expectedProgressByDay) /
            expectedPerformance) *
          (i + 1);
      }

      const realSeries: SeriesOptionsType = {
        name: this.translateService.instant(
          'FORECAST_GRAPH.CITIES_CHALLENGE.REAL_PROGRESS'
        ),
        type: 'areaspline',
        data: this.citiesChallengeDashboard.citiesChallengeDays.map(
          (citiesChallengeDay) => citiesChallengeDay.totalProgress
        ),
      };

      const expectedSeries: SeriesOptionsType = {
        name: this.translateService.instant(
          'FORECAST_GRAPH.CITIES_CHALLENGE.EXPECTED_PROGRESS'
        ),
        type: 'line',
        data: days.map((_day, index) => (100 / days.length) * (index + 1)),
      };

      const forecastSeries: SeriesOptionsType = {
        name: this.translateService.instant(
          'FORECAST_GRAPH.CITIES_CHALLENGE.FORECAST_PROGRESS'
        ),
        type: 'line',
        dashStyle: 'Dash',
        data: forecastData,
      };

      return [realSeries, expectedSeries, forecastSeries];
    }
  }

  public getChallengeDayPerformance(date: MatDatepickerInputEvent<Date>): void {
    const isoDate = Helpers.createISODate(new Date(date.value));
    const challengePerformance = this.citiesChallengeDashboard.citiesChallengeDays.find(
      (challengeDay) => challengeDay.date === isoDate
    );

    if (challengePerformance) {
      this.challengeDayPerformance = challengePerformance;
    } else {
      this.challengeDayPerformance = new CitiesChallengeDayPerformance({
        date:
          this.today < this.citiesChallengeDashboard.citiesChallenge.endDate
            ? this.today
            : this.citiesChallengeDashboard.citiesChallenge.endDate,
        ...this.citiesChallengeDashboard.citiesChallenge.totalPerformance.toObject(),
      });
      this.dateFormControl.setValue(this.today);
      this.userMessageService.snackBarMessage(
        'CITIES_CHALLENGE.NO_DATA_MESSAGE'
      );
    }

    this.calculatePerformanceCards();
  }

  private setLocaleToDateAdapter(): void {
    if (this.translateService.currentLang === 'en') {
      this.dateAdapter.setLocale(enUS);
    } else {
      this.dateAdapter.setLocale(de);
    }
  }

  public downloadReport(): void {
    this.downloading = true;
    this.reportsService
      .generateCitiesChallengeReport(
        this.citiesChallengeDashboard.citiesChallenge,
        this.challengeDayPerformance,
        this.citiesChallengeDashboard.companyId
      )
      .then((downloadUrl) => window.open(downloadUrl, '_blank'))
      .catch((error) => {
        console.log(
          'Cities challenge dashboard - Error downloading report: ',
          error
        );
        this.userMessageService.snackBarMessage(
          'CITIES_CHALLENGE.REPORT.ERROR_CREATING_FILE'
        );
      })
      .finally(() => (this.downloading = false));
  }
}
