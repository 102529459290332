import { Component, Input, OnInit } from '@angular/core';
import { Helpers } from '../../../../helpers/helpers';
import { IconNames } from '../../../../models/assets-constants';
import { Company } from '../../../../models/company.model';
import { TeamChallenge as TeamChallengeV2 } from '../../../../models/team-challenge/team-challenge/team-challenge.model';
import { WeeklyHistory } from '../../../../models/team-challenge/weekly-history/weekly-history.model';
import { ReportsService } from '../../../../services/reports/reports.service';
import { UserMessageService } from '../../../../services/user-message/user-message.service';
import { IKpiList } from '../../../../shared/widgets/kpi-list/kpi-list.component';
import {
  IUserRanking,
  IUserRankingItem,
} from '../../../../shared/widgets/user-ranking/user-ranking.component';
import { TeamRankingBy } from '../team-ranking-table/team-ranking-table.component';
import { Team } from '../../../../models/team-challenge/team/team.model';
import { ITeamRanking } from '../../../../models/team-challenge/interfaces/team-ranking.interface';
import { ITeamChallengeKpis } from '../../../../services/team-challenge/team-challenge.service';

export interface ITeamChallengeOverview {
  teamChallenge: TeamChallengeV2;
  teamsMap: Map<string, Team>;
  weeklyHistories: Array<WeeklyHistory>;
  teamChallengeKpi: ITeamChallengeKpis;
  company: Company;
}

export const RANKING_TABS = {
  ACCUMULATED_POINTS: 0,
  AVERAGE_PERFORMANCE: 1,
};

@Component({
  selector: 'app-team-challenge-overview',
  templateUrl: './team-challenge-overview.component.html',
  styleUrls: ['./team-challenge-overview.component.scss'],
})
export class TeamChallengeOverviewComponent implements OnInit {
  @Input() teamChallengeOverview: ITeamChallengeOverview;

  public maxDate: string;
  public today = Helpers.createISODateTime();
  public downloading = false;
  public challengeKpiList: IKpiList;
  public pointsRanking: IUserRanking;
  public performanceRanking: IUserRanking;
  public pointsTableRanking: Array<ITeamRanking>;
  public averageTableRanking: Array<ITeamRanking>;
  public selectedTab = RANKING_TABS.ACCUMULATED_POINTS;
  public teamRankingBy = TeamRankingBy;

  constructor(
    private reportsService: ReportsService,
    private userMessageService: UserMessageService
  ) {}

  ngOnInit(): void {
    this.setKpiLists();
    this.setPointsRanking();
    this.setPerformanceRanking();
    this.setPointsTableRanking();
    this.setAverageTableRanking(this.teamChallengeOverview.weeklyHistories[0]);
  }

  private setKpiLists(): void {
    const kpis = this.teamChallengeOverview.teamChallengeKpi;

    this.challengeKpiList = {
      title: 'KPI_LIST.TEAM_CHALLENGE.TITLE_OVERVIEW',
      list: [
        {
          name: 'KPI_LIST.TEAM_CHALLENGE.TOTAL_PARTICIPANTS',
          value: kpis.participants,
        },
        {
          name: 'KPI_LIST.TEAM_CHALLENGE.TOTAL_STEPS',
          value: kpis.totalPerformance,
        },
        {
          name: 'KPI_LIST.TEAM_CHALLENGE.TOTAL_KM',
          value: kpis.totalKms,
        },
        {
          name: 'KPI_LIST.TEAM_CHALLENGE.TEAMS',
          value: kpis.teams,
        },
        {
          name: 'KPI_LIST.TEAM_CHALLENGE.TOTAL_STEPS_AVERAGE',
          value: kpis.totalAverage,
        },
        {
          name: 'KPI_LIST.TEAM_CHALLENGE.TOTAL_SYNCS',
          value: kpis.totalSyncs,
        },
      ],
    };
  }

  private setPointsRanking(): void {
    const ranking: Array<IUserRankingItem> = this.teamChallengeOverview.teamChallenge
      .sort()
      .map((teamTotalPerformance) => ({
        avatar: this.teamChallengeOverview.teamsMap.get(
          teamTotalPerformance.id
        )!.avatar,
        nickname: this.teamChallengeOverview.teamsMap.get(
          teamTotalPerformance.id
        )!.name,
        performance: teamTotalPerformance.points!,
      }));
    this.pointsRanking = {
      title: 'USER_RANKING.TEAM_CHALLENGE.POINTS_TITLE',
      subtitle: 'USER_RANKING.TEAM_CHALLENGE.POINTS_SUBTITLE',
      ranking,
      badgeIcons: [
        IconNames.FirstPlaceTeamChallenge,
        IconNames.SecondPlaceTeamChallenge,
        IconNames.ThirdPlaceTeamChallenge,
      ],
      emptyRankingMessage: 'USER_RANKING.TEAM_CHALLENGE.POINTS_EMPTY_MESSAGE',
    };
  }

  private setPerformanceRanking(): void {
    const ranking: Array<IUserRankingItem> = this.teamChallengeOverview.weeklyHistories[0]
      .sort()
      .map((teamWeeklyPerformance) => ({
        avatar: this.teamChallengeOverview.teamsMap.get(
          teamWeeklyPerformance.id
        )!.avatar,
        nickname: this.teamChallengeOverview.teamsMap.get(
          teamWeeklyPerformance.id
        )!.name,
        performance: teamWeeklyPerformance.average,
      }));
    this.performanceRanking = {
      title: 'USER_RANKING.TEAM_CHALLENGE.PERFORMANCE_TITLE',
      subtitle: 'USER_RANKING.TEAM_CHALLENGE.PERFORMANCE_SUBTITLE',
      ranking,
      badgeIcons: [],
      emptyRankingMessage:
        'USER_RANKING.TEAM_CHALLENGE.PERFORMANCE_EMPTY_MESSAGE',
    };
  }

  private setPointsTableRanking(): void {
    this.pointsTableRanking = this.teamChallengeOverview.teamChallenge
      .sort()
      .map((teamTotalPerformance, index) => {
        const team = this.teamChallengeOverview.teamsMap.get(
          teamTotalPerformance.id
        )!;
        return {
          rank: index + 1,
          teamId: team.id,
          teamAvatar: team.avatar,
          teamName: team.name,
          teamSize: team.memberIds.length,
          teamLocation: team.location,
          teamColor: team.color,
          performance: teamTotalPerformance.performance,
          syncs: teamTotalPerformance.syncs,
          points: teamTotalPerformance.points,
          average: teamTotalPerformance.average,
        };
      });
  }

  public setAverageTableRanking(weeklyHistory: WeeklyHistory): void {
    this.averageTableRanking = weeklyHistory
      .sort()
      .map((teamTotalPerformance, index) => {
        const team = this.teamChallengeOverview.teamsMap.get(
          teamTotalPerformance.id
        )!;
        return {
          rank: index + 1,
          teamId: team.id,
          teamAvatar: team.avatar,
          teamName: team.name,
          teamSize: team.memberIds.length,
          teamLocation: team.location,
          teamColor: team.color,
          average: teamTotalPerformance.average,
          performance: teamTotalPerformance.performance,
          syncs: teamTotalPerformance.syncs,
          points: teamTotalPerformance.points,
        };
      });
  }

  public downloadReport(): void {
    // this.downloading = true;
    // this.reportsService
    //   .generateTeamReport(
    //     this.teamChallengeOverview.company,
    //     this.teamChallengeOverview.teamChallenge,
    //     this.teamChallengeOverview.statistics,
    //   )
    //   .then((blob) => this.reportsService.openBlob(blob))
    //   .catch((error) => {
    //     console.log(
    //       'Cities challenge dashboard - Error downloading report: ',
    //       error
    //     );
    //     this.userMessageService.snackBarMessage(
    //       'CITIES_CHALLENGE.REPORT.ERROR_CREATING_FILE'
    //     );
    //   })
    //   .finally(() => (this.downloading = false));
  }
}
