import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Helpers } from 'src/app/helpers/helpers';

export interface IKpiItem {
  name: string;
  value: number;
  icon?: string;
}

export interface IKpiList {
  title: string;
  list: Array<IKpiItem>;
}

@Component({
  selector: 'app-kpi-list',
  templateUrl: './kpi-list.component.html',
  styleUrls: ['./kpi-list.component.scss'],
})
export class KpiListComponent implements AfterViewInit {
  @Input() kpiList: IKpiList;
  @ViewChild('kpiCardContent', { static: true }) contentElement: ElementRef;

  constructor() {}

  ngAfterViewInit(): void {
    Helpers.calculateCardContentHeight(this.contentElement, 16);
  }
}
