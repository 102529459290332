import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AssetsConstants, IconNames } from 'src/app/models/assets-constants';

export interface IIcon {
  name: string;
  path: string;
}
@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private registeredIcons: Set<string> = new Set<string>();
  private iconsToRegister: Array<IIcon> = [];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  // Function to register all the iconsToRegister array. It is called at the app initialization by app.module
  public registerStandardIcons(): void {
    this.iconsToRegister = Object.keys(AssetsConstants).map((key) => ({
      name: IconNames[key],
      path: AssetsConstants[key],
    }));
    this.iconsToRegister.forEach((icon) => this.registerSvgIcon(icon));
  }
  // Register an SVG icon
  public registerSvgIcon(icon: IIcon): void {
    if (!this.iconExists(icon.name)) {
      if (icon.name && icon.path) {
        this.matIconRegistry.addSvgIcon(
          icon.name,
          this.sanitizer.bypassSecurityTrustResourceUrl(icon.path)
        );
        // Keep track of registered icons
        this.registeredIcons.add(icon.name);
      }
    }
  }

  // Retrieve an SVG icon
  public getSvgIcon(name: string): string {
    if (this.iconExists(name)) {
      return name;
    } else {
      console.warn(`Icon "${name}" does not exist.`);
      // You can return a default icon or throw an error if needed
      return 'default-icon';
    }
  }

  // Check if an icon exists
  public iconExists(name: string): boolean {
    return this.registeredIcons.has(name);
  }
}
