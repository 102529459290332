export enum AssetsConstants {
  // icons
  SentEmailOk = 'assets/icons/sent-message-ok.svg',
  ClockOutlined = 'assets/icons/clock-outlined.svg',
  SwordCrossedHistory = 'assets/icons/sword-crossed-history.svg',
  Earth = 'assets/icons/earth-icon.svg',
  GroupPeople = 'assets/img/group-people.svg',

  // activities
  StepsOrangeFilled = 'assets/icons/steps-orange-filled.svg',
  SyncOutlined = 'assets/icons/sync-outlined.svg',
  BalanceAverage = 'assets/icons/balance-average.svg',
  StandingManWithOpenArms = 'assets/icons/standing-man-with-arms-open.svg',
  BikeOrangeFilled = 'assets/icons/bike-orange-filled.svg',
  ExerciseOrangeFilled = 'assets/icons/exercise-orange-filled.svg',

  // img
  PencilOutline = 'assets/img/pencil-outline.svg',
  TrashOutline = 'assets/img/trash-outline.svg',

  // badges

  // Duel Challenge
  MostStepsOfTheMonthDuel = 'assets/duel-challenge/most-steps-of-the-month/most-steps-of-the-month.svg',
  MostStepsOfTheMonthDeactivatedDuel = 'assets/duel-challenge/most-steps-of-the-month/most-steps-of-the-month-deactivated.svg',
  FirstPlaceDuel = 'assets/duel-challenge/podium/1-place-duel.svg',
  SecondPlaceDuel = 'assets/duel-challenge/podium/2-place-duel.svg',
  ThirdPlaceDuel = 'assets/duel-challenge/podium/3-place-duel.svg',
  DeactivatedPlacePodiumDuel = 'assets/duel-challenge/podium/deactivated-place-duel.svg',

  // Cities Challenge
  FirstPlaceCitiesChallenge = 'assets/cities-challenge/badges/individual-podium/1-place-individual-cities.svg',
  SecondPlaceCitiesChallenge = 'assets/cities-challenge/badges/individual-podium/2-place-individual-cities.svg',
  ThirdPlaceCitiesChallenge = 'assets/cities-challenge/badges/individual-podium/3-place-individual-cities.svg',
  DeactivatedPlaceCitiesChallenge = 'assets/cities-challenge/badges/individual-podium/deactivated-place-individual-cities.svg',

  // Team Challenge
  FirstPlaceTeamChallenge = 'assets/team-challenge/1-place-team-challenge-badge.svg',
  SecondPlaceTeamChallenge = 'assets/team-challenge/2-place-team-challenge-badge.svg',
  ThirdPlaceTeamChallenge = 'assets/team-challenge/3-place-team-challenge-badge.svg',

  // Challenges
  GroupOfPeopleAndLogo = 'assets/img/group-of-people-and-logo.svg',
  GuyWithPhone = 'assets/img/guy-with-phone.svg',
}

export enum IconNames {
  // icons
  SentEmailOk = 'sent-email-ok',
  ClockOutlined = 'clock-outlined',
  SwordCrossedHistory = 'sword-crossed-history',
  Earth = 'earth',
  GroupPeople = 'group-people',

  // activities
  StepsOrangeFilled = 'steps-orange-filled',
  SyncOutlined = 'sync-outlined',
  BalanceAverage = 'balance-average',
  StandingManWithOpenArms = 'standing-man-with-arms-open',
  BikeOrangeFilled = 'bike-orange-filled',
  ExerciseOrangeFilled = 'exercise-orange-filled',

  // img
  PencilOutline = 'pencil-outline',
  TrashOutline = 'trash-outline',

  // badges

  // Duel Challenge
  MostStepsOfTheMonthDuel = 'most-steps-of-the-month',
  MostStepsOfTheMonthDeactivatedDuel = 'most-steps-of-the-month-deactivated',
  FirstPlaceDuel = '1-place-duel',
  SecondPlaceDuel = '2-place-duel',
  ThirdPlaceDuel = '3-place-duel',
  DeactivatedPlacePodiumDuel = 'deactivated-place-duel',

  // Cities Challenge
  FirstPlaceCitiesChallenge = '1-place-individual-cities',
  SecondPlaceCitiesChallenge = '2-place-individual-cities',
  ThirdPlaceCitiesChallenge = '3-place-individual-cities',
  DeactivatedPlaceCitiesChallenge = 'deactivated-place-individual-cities',

  // Team Challenge
  FirstPlaceTeamChallenge = '1-place-team-challenge-badge',
  SecondPlaceTeamChallenge = '2-place-team-challenge-badge',
  ThirdPlaceTeamChallenge = '3-place-team-challenge-badge',

  // Challenges
  GroupOfPeopleAndLogo = 'group-of-people-and-logo',
}
